import type { IContentItem } from "~/lib/cmsContent";
import type { ISlide } from "~/lib/slide";
import { serviceList, staffList } from "~/state/state";

export interface ISpecialization {
    id: number;
    name: string;
    title: string;
    description: string;
    keywords?: string;
    avatar: string;
    staffIds: number[];
    serviceIds: number[];
    content?: IContentItem[];
    order: number;
    images: ISlide[];
}

export class Specialization implements ISpecialization {
    id;
    name;
    title;
    description;
    keywords;
    avatar;
    staffIds;
    serviceIds;
    content;
    order;
    images;

    constructor(dto: ISpecialization) {
        this.id = dto.id;
        this.name = dto.name;
        this.title = dto.title;
        this.title = dto.title;
        this.description = dto.description;
        this.keywords = dto.keywords;
        this.avatar = dto.avatar || "/src/specialization-avatar.png";
        this.staffIds = dto.staffIds || [];
        this.serviceIds = dto.serviceIds || [];
        this.content = dto.content || [];
        this.order = dto.order || 0;
        this.images = dto.images || [];
    }

    get servicesNames() {
        return this.services.map(({ id, name }) => ({ id, name }));
    }

    get services() {
        return serviceList.getElements().filter(({ id }) => this.serviceIds.includes(id));
    }

    get staff() {
        return staffList.getElements().filter(({ id }) => this.staffIds.includes(id));
    }

    get staffWithoutContent() {
        return staffList.getElementsWithoutContent().filter(({ id }) => this.staffIds.includes(id));
    }

    get servicesNamesAndPrices() {
        return serviceList
            .getElements()
            .filter(({ id }) => this.serviceIds.includes(id))
            .map((el) => ({ id: el.id, name: el.name, pricePositions: el.pricePositions }));
    }

    get titlesList() {
        return this.content.filter((el) => ["title-1", "title-2"].includes(el.type) && el.text).map((el) => el.text || "");
    }

    get meta() {
        return {
            title: this.title,
            description: this.description,
            keywords: this.keywords
        };
    }
}

export class SpecializationList {
    elements: Ref<Specialization[]>;

    constructor(dto: ISpecialization[]) {
        this.elements = ref(dto.map((el) => new Specialization(el)).sort((a, b) => a.order - b.order) || []);
    }

    setElements(dto: ISpecialization[]) {
        this.elements.value = dto.map((el) => new Specialization(el)).sort((a, b) => a.order - b.order) || [];
    }

    getElements() {
        return this.elements.value;
    }

    getElementsWithoutContent() {
        return this.elements.value.map(({ id, name, servicesNames, description, avatar }) => ({ id, name, servicesNames, description, avatar }));
    }

    getNames() {
        return this.elements.value.map((el) => el.name);
    }

    getElementById(id: string | number) {
        return this.elements.value.find((el) => el.id == id);
    }
}
