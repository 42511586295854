import type { IContentItem } from "./cmsContent";
import type { ISlide } from "./slide";
import { specializationList } from "~/state/state";

export interface IMetaData {
    title?: string;
    description?: string;
    keywords?: string;
}

export interface IStaffItem {
    avatarImageName?: string;
    content?: IContentItem[];
    description?: string;
    id: number;
    medicalTitle?: string;
    name: string;
    specializationIds: number[];
    staffType?: string;
    meta?: IMetaData;
    images: ISlide[];
}

export interface IStaffItemWithoutContent extends Omit<IStaffItem, "avatarImageName" | "content" | "images"> {
    specializationNames: string[];
    src: string;
}

export class Staff implements IStaffItem {
    private static imagePath = "/img/staff/";
    private static imageExtensions = [".webp"];

    id;
    name;
    medicalTitle;
    specializationIds;
    description;
    avatarImageName;
    content;
    staffType;
    meta;
    images;

    constructor(dto: IStaffItem) {
        this.id = dto.id;
        this.name = dto.name;
        this.medicalTitle = dto.medicalTitle || "";
        this.specializationIds = dto.specializationIds || [];
        this.description = dto.description || "";
        this.avatarImageName = dto.avatarImageName || "avatar_placeholder";
        this.content = dto.content || [];
        this.staffType = dto.staffType;
        this.meta = dto.meta;
        this.images = dto?.images || [];
    }

    get withoutContent(): IStaffItemWithoutContent {
        return {
            id: this.id,
            name: this.name,
            medicalTitle: this.medicalTitle,
            staffType: this.staffType,
            specializationIds: this.specializationIds,
            specializationNames: this.specializationNames,
            description: this.description,
            src: this.avatarScr
        };
    }

    get avatarScr() {
        return Staff.imagePath + this.avatarImageName + Staff.imageExtensions[0];
    }

    get titlesList() {
        return this.content.filter((el) => el.type.includes("title") && el.text).map((el) => el.text || "");
    }

    get avatarSources() {
        return Staff.imageExtensions.map((ext) => Staff.imagePath + this.avatarImageName + ext);
    }

    get specializationNames() {
        return this.specializationIds.length ? this.specializationIds.map((id) => specializationList.getElementById(id)?.name || "") : [];
    }
}

export class StaffList {
    elements: Ref<Staff[]>;

    constructor(dto: IStaffItem[]) {
        this.elements = ref(dto.map((el) => new Staff(el)) || []);
    }

    setElements(dto: Staff[]) {
        this.elements.value = dto.map((el) => new Staff(el)) || [];
    }

    getElements() {
        return this.elements.value;
    }

    getElementById(id: number | string) {
        return this.elements.value.find((el) => el.id == id);
    }

    getElementsWithoutContent(params?: { onlyMedical: boolean }) {
        if (params?.onlyMedical) return this.elements.value.map((el) => el.withoutContent).filter((el) => el.staffType !== "bok");
        else return this.elements.value.map((el) => el.withoutContent);
    }
}
