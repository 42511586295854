import type { IPricePosition } from "~/lib/priceList";

export const priceListDto: IPricePosition[] = [
    { id: 4, medicalServiceIds: [13], prices: { regular: [8000], promotion: [] }, name: "Tummy Liposuction" },
    { id: 5, medicalServiceIds: [13], prices: { regular: [12000], promotion: [] }, name: "Tummy and Flanks Liposuction" },
    { id: 6, medicalServiceIds: [13], prices: { regular: [17000], promotion: [] }, name: "360 Body Liposuction (Tummy, Flanks and Back)" },
    { id: 7, medicalServiceIds: [13], prices: { regular: [7500], promotion: [] }, name: "Love Handles Liposuction (Lower Flanks Liposuction)" },
    { id: 8, medicalServiceIds: [13], prices: { regular: [7000], promotion: [] }, name: "Back Liposuction" },
    { id: 9, medicalServiceIds: [13], prices: { regular: [8000], promotion: [] }, name: "Buttocks Liposuction" },
    { id: 10, medicalServiceIds: [13], prices: { regular: [7000], promotion: [] }, name: "Hips Liposuction" },
    { id: 11, medicalServiceIds: [13], prices: { regular: [9000], promotion: [] }, name: "Thighs Liposuction" },
    { id: 12, medicalServiceIds: [13], prices: { regular: [6000], promotion: [] }, name: "Knees Liposuction" },
    { id: 13, medicalServiceIds: [13], prices: { regular: [7000], promotion: [] }, name: "Calfs Liposuction" },
    { id: 14, medicalServiceIds: [13], prices: { regular: [4000], promotion: [] }, name: "Pubic Area Liposuction" },
    { id: 15, medicalServiceIds: [13], prices: { regular: [7000], promotion: [] }, name: "Arms Liposuction" },
    { id: 16, medicalServiceIds: [13], prices: { regular: [5500], promotion: [] }, name: "Double Chin Liposuction" },
    { id: 17, medicalServiceIds: [13], prices: { regular: [7500], promotion: [] }, name: "Face Liposuction" },
    { id: 18, medicalServiceIds: [13], prices: { regular: [6500], promotion: [] }, name: "Neck Liposuction" },
    { id: 19, medicalServiceIds: [13], prices: { regular: [6000], promotion: [] }, name: "Cheeks/Jowls Liposuction" },
    { id: 20, medicalServiceIds: [7], prices: { regular: [12500], promotion: [] }, name: "Mini-Abdominoplasty" },
    { id: 21, medicalServiceIds: [7], prices: { regular: [17000], promotion: [] }, name: "Abdominoplasty (Tummy Tuck)" },
    { id: 22, medicalServiceIds: [7], prices: { regular: [20000], promotion: [] }, name: "Fleur-de-Lis Abdominoplasty" },
    { id: 23, medicalServiceIds: [7], prices: { regular: [23000], promotion: [] }, name: "Abdominoplasty with Liposuction" },
    { id: 24, medicalServiceIds: [7], prices: { regular: [24000], promotion: [] }, name: "Abdominoplasty 360" },
    { id: 25, medicalServiceIds: [39], prices: { regular: [25000], promotion: [] }, name: "360 Body Lift (Tummy, Back, Buttocks)" },
    { id: 26, medicalServiceIds: [35], prices: { regular: [11000], promotion: [] }, name: "Chest Lift" },
    { id: 27, medicalServiceIds: [28], prices: { regular: [10000], promotion: [] }, name: "Arm Lift" },
    { id: 28, medicalServiceIds: [27], prices: { regular: [13000], promotion: [] }, name: "Thigh Lift" },
    { id: 29, medicalServiceIds: [23], prices: { regular: [10000], promotion: [] }, name: "Lip Lift" },
    { id: 31, medicalServiceIds: [8], prices: { regular: [14000], promotion: [] }, name: "Brasilian Butt Lift" },
    { id: 32, medicalServiceIds: [34], prices: { regular: [12000], promotion: [] }, name: "Calfs Implants" },
    { id: 33, medicalServiceIds: [36], prices: { regular: [10000], promotion: [] }, name: "Chin Implant" },
    { id: 35, medicalServiceIds: [30], prices: { regular: [14000], promotion: [] }, name: "Bra-Line Back Lift" },
    { id: 36, medicalServiceIds: [14], prices: { regular: [28000], promotion: [] }, name: "Mommy Makeover: Breast Lift and Tummy Tuck" },
    {
        id: 37,
        medicalServiceIds: [14],
        prices: { regular: [33000], promotion: [] },
        name: "Mommy Makeover with Implants: Breast Lift and Augmentation with Tummy Tuck"
    },
    { id: 38, medicalServiceIds: [21], prices: { regular: [15000], promotion: [] }, name: "Breast Augmentation with Fat Transfer" },
    { id: 39, medicalServiceIds: [12], prices: { regular: [22000], promotion: [] }, name: "Facelift" },
    { id: 40, medicalServiceIds: [12], prices: { regular: [24000], promotion: [] }, name: "Facelift with Lower Eyelid Surgery" },
    { id: 41, medicalServiceIds: [12], prices: { regular: [26000], promotion: [] }, name: "Facelift with Upper and Lower Eyelid Surgery" },
    { id: 42, medicalServiceIds: [12], prices: { regular: [33000], promotion: [] }, name: "Face and Neck Lift" },
    { id: 44, medicalServiceIds: [33], prices: { regular: [8000], promotion: [] }, name: "Brow Lift" },
    { id: 45, medicalServiceIds: [9], prices: { regular: [6000], promotion: [] }, name: "Lower Eyelid Surgery" },
    { id: 46, medicalServiceIds: [9], prices: { regular: [5500], promotion: [] }, name: "Upper Eyelid Surgery" },
    { id: 47, medicalServiceIds: [9], prices: { regular: [10000], promotion: [] }, name: "Upper and Lower Eyelid Surgery" },
    { id: 48, medicalServiceIds: [9], prices: { regular: [7000], promotion: [] }, name: "Cantoplasty" },
    { id: 49, medicalServiceIds: [9], prices: { regular: [5000], promotion: [] }, name: "Canthopexy (Foxy Eyes)" },
    { id: 50, medicalServiceIds: [38], prices: { regular: [10000], promotion: [] }, name: "Hairline Lowering" },
    { id: 51, medicalServiceIds: [15], prices: { regular: [5500], promotion: [] }, name: "Otoplasty (Ear Surgery)" },
    { id: 52, medicalServiceIds: [16], prices: { regular: [22000], promotion: [] }, name: "Full Rhinoplasty" },
    { id: 53, medicalServiceIds: [16], prices: { regular: [12000], promotion: [] }, name: "Nose Tip Surgery" },
    { id: 54, medicalServiceIds: [24], prices: { regular: [6000], promotion: [] }, name: "Malar Bags Removal" },
    { id: 55, medicalServiceIds: [11, 29], prices: { regular: [6500], promotion: [] }, name: "Bichectomy Surgery (Buccal Fat Removal)" },
    { id: 56, medicalServiceIds: [37], prices: { regular: [9000], promotion: [] }, name: "Gynecomastia" },
    { id: 57, medicalServiceIds: [37], prices: { regular: [12000], promotion: [] }, name: "Gynecomastia with liposuction" },
    { id: 58, medicalServiceIds: [25], prices: { regular: [17000], promotion: [] }, name: "Abdominal Sculpting for Men" },
    { id: 59, medicalServiceIds: [25], prices: { regular: [19000], promotion: [] }, name: "Abdominal and Chest Sculpting for Men" },
    { id: 60, medicalServiceIds: [22], prices: { regular: [15000], promotion: [] }, name: "Ligament Suspensorium Penis Augmentation" },
    { id: 62, medicalServiceIds: [22], prices: { regular: [9500], promotion: [] }, name: "Stem Cell Penis Augmentation" },
    { id: 63, medicalServiceIds: [18], prices: { regular: [6000], promotion: [] }, name: "Labiaplasty - Labia Minora" },
    { id: 64, medicalServiceIds: [18], prices: { regular: [7000], promotion: [] }, name: "Labiaplasty - Labia Minora and Majora" },
    { id: 65, medicalServiceIds: [17], prices: { regular: [7500], promotion: [] }, name: "Vaginoplasty" },
    { id: 66, medicalServiceIds: [17, 18], prices: { regular: [12000], promotion: [] }, name: "Labiaplasty and Vaginoplasty" },
    { id: 67, medicalServiceIds: [17], prices: { regular: [17000], promotion: [] }, name: "Vaginoplasty and prolapsed uterus treatment" },
    { id: 68, medicalServiceIds: [19], prices: { regular: [11000], promotion: [] }, name: "Prolapsed uterus treatment" },
    { id: 69, medicalServiceIds: [1], prices: { regular: [8000], promotion: [] }, name: "Laparoscopic Hernia Repair" },
    { id: 70, medicalServiceIds: [2], prices: { regular: [8000], promotion: [] }, name: "Gallbladder Removal" },
    { id: 71, medicalServiceIds: [4], prices: { regular: [22000], promotion: [] }, name: "Gastric Sleeve" },
    { id: 72, medicalServiceIds: [3], prices: { regular: [8500], promotion: [] }, name: "Gastric Balloon (6 months)" },
    { id: 73, medicalServiceIds: [3], prices: { regular: [9000], promotion: [] }, name: "Gastric Balloon (12 months)" },
    { id: 74, medicalServiceIds: [3], prices: { regular: [3000], promotion: [] }, name: "Gastric Balloon Removal" },
    { id: 75, medicalServiceIds: [20], prices: { regular: [23000], promotion: [] }, name: "Gastric Bypass" },
    { id: 76, medicalServiceIds: [5], prices: { regular: [28000], promotion: [] }, name: "Total Hip Replacement" },
    { id: 77, medicalServiceIds: [6], prices: { regular: [28000], promotion: [] }, name: "Total Knee Replacement" },
    {
        id: 78,
        medicalServiceIds: [10],
        prices: { regular: [23000], promotion: [] },
        name: "Breast Implants Exchange for Smaller Implants with a Breast Lift"
    },
    { id: 79, medicalServiceIds: [10], prices: { regular: [18000], promotion: [] }, name: "Breast Implants Exchange for Larger Implants" },
    { id: 80, medicalServiceIds: [10], prices: { regular: [16000], promotion: [] }, name: "Breast Augmentation with Implants" },
    { id: 81, medicalServiceIds: [10, 31], prices: { regular: [22000], promotion: [] }, name: "Breast Implants with Breast Lift" },
    { id: 82, medicalServiceIds: [10], prices: { regular: [24000], promotion: [] }, name: "Endoscopic Breast Augmentation" },
    { id: 83, medicalServiceIds: [40], prices: { regular: [6500], promotion: [] }, name: "Areolae and Nipples Reduction" },
    { id: 84, medicalServiceIds: [31], prices: { regular: [16000], promotion: [] }, name: "Breast Lift" },
    { id: 85, medicalServiceIds: [32], prices: { regular: [16000], promotion: [] }, name: "Breast Reduction" }
];
