import { contactInfo } from "~/cms/contact";
import { priceListDto } from "~/cms/priceList";
import { reviews } from "~/cms/reviews";
import { serviceListDto } from "~/cms/services";
import { specializationListDto } from "~/cms/specializations";
import { staffListDto } from "~/cms/staffs";
import { ContactInfo } from "~/lib/contactInfo";
import { PriceList } from "~/lib/priceList";
import { ReviewList } from "~/lib/review";
import { ServiceList } from "~/lib/service";
import { SpecializationList } from "~/lib/specialization";
import { StaffList } from "~/lib/staff";

export const specializationList = new SpecializationList(specializationListDto);
export const serviceList = new ServiceList(serviceListDto);
export const staffList = new StaffList(staffListDto);
export const contactData = reactive(new ContactInfo(contactInfo));
export const reviewList = new ReviewList(reviews);
export const priceList = new PriceList(priceListDto);
