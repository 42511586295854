type TPrice = [number] | [number, number] | [];

export interface IPricePosition {
    id: number;
    medicalServiceIds: number[];
    name: string;
    prices: {
        regular: TPrice;
        promotion: TPrice;
    };
}

export class PricePosition implements IPricePosition {
    id;
    medicalServiceIds;
    name;
    prices;

    constructor(dto: IPricePosition) {
        this.id = dto.id;
        this.medicalServiceIds = dto.medicalServiceIds || [];
        this.name = dto.name || "";
        this.prices = dto.prices || { regular: [], promotion: [] };
    }
}

export class PriceList {
    elements: Ref<PricePosition[]>;

    constructor(dto: IPricePosition[]) {
        this.elements = ref(dto.map((el) => new PricePosition(el)).sort((a, b) => a.name.localeCompare(b.name)) || []);
    }

    setElements(dto: IPricePosition[]) {
        this.elements.value = dto.map((el) => new PricePosition(el)).sort((a, b) => a.name.localeCompare(b.name)) || [];
    }

    getElements() {
        return this.elements.value;
    }

    getNames() {
        return this.elements.value.map((el) => el.name);
    }

    getElementById(id: string | number) {
        return this.elements.value.find((el) => el.id == id);
    }
}
