interface TContactDto {
    phone: string;
    email: string;
    address: {
        street: string;
        sipCode: string;
        city: string;
        googleUrl: string;
    };
}

export class ContactInfo implements TContactDto {
    phone = "";
    email = "";
    address = {
        street: "",
        sipCode: "",
        city: "",
        googleUrl: ""
    };

    constructor(dto: TContactDto) {
        this.phone = dto.phone;
        this.email = dto.email;
        this.address = structuredClone(dto.address);
    }

    get toArray() {
        return [
            {
                icon: "marker",
                title: "Our location",
                linkTitle: "Visit our location.",
                text: `${this.address.street}, ${this.address.sipCode.replaceAll("-", "&#8209;")}&nbsp;${this.address.city.replaceAll(" ", "&nbsp;")}`,
                url: this.address.googleUrl,
                color: {
                    light: "purple-light",
                    dark: "purple"
                },
                target: "_blank"
            },
            {
                icon: "phone",
                title: "Phone number",
                linkTitle: "Call to us.",
                text: `${this.phone}`,
                url: `tel:${this.phone.replaceAll(/\s/gi, "")}`,
                color: {
                    light: "text-red",
                    dark: "text-red"
                },
                target: "_self"
            },
            {
                icon: "email",
                title: "Email",
                linkTitle: "Send mail.",
                text: `${this.email}`,
                url: `mailto:${this.email}`,
                color: {
                    light: "cyan",
                    dark: "cyan"
                },
                target: "_self"
            }
        ];
    }

    get toObject() {
        return {
            address: this.toArray[0],
            phone: this.toArray[1],
            email: this.toArray[2]
        };
    }
}
