import type { IService } from "~/lib/service";
import { useUniqueId } from "@/utils/useUniqueId";
export const serviceListDto: IService[] = [
    {
        id: 1,
        title: "", // todo
        name: "Laparoscopic Hernia Repair",
        pricePositionIds: [69],
        specializationIds: [1],
        images: [],
        staffIds: [5],
        reviewIds: [],
        frequentlyAskedQuestions: {
            title: "Hernia FAQ’s",
            subTitle: "Frequently-Asked-Question about Hernia",
            text: "", // todo
            questions: [] // todo
        },
        // todo description
        description: "",
        // todo
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ]
    },
    {
        id: 2,
        title: "", // todo
        name: "Gallbladder Removal",
        pricePositionIds: [70],
        specializationIds: [1],
        images: [],
        staffIds: [5], // todo
        reviewIds: [8, 9],
        frequentlyAskedQuestions: {
            title: "Hernia FAQ’s",
            subTitle: "Frequently-Asked-Question about Hernia",
            text: "", // todo
            questions: [] // todo
        },
        // todo description
        description: "",
        // todo
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ]
    },
    {
        id: 3,
        name: "Gastric Balloon",
        title: "Gastric Balloon Surgery at Allmedica, Poland: A Non-Surgical Weight Loss Solution",
        description:
            "Are you looking for a safe and effective way to kickstart your weight loss journey without undergoing surgery? The gastric balloon procedure might be the answer you've been searching for. This non-surgical approach offers a minimally invasive way to help you shed excess weight and improve your overall health and well-being.",
        keywords:
            "Allmedica, Gastric Balloon Surgery, bariatric surgery, weight loss, health improvement, obesity treatment, bariatric procedures, patient care",
        pricePositionIds: [72, 73, 74],
        specializationIds: [3],
        images: [],
        staffIds: [13],
        reviewIds: [10, 11, 12],
        frequentlyAskedQuestions: {
            title: "Gastric Balloon Surgery FAQ’s",
            subTitle: "Frequently-Asked-Question about Gastric Balloon Surgery",
            text: "Get all your questions answered with our comprehensive FAQ section on Gastric Balloon Surgery. Learn about the procedure, its benefits, potential risks, and more. If you're curious about our packages or how cosmetic procedures could transform your life, reach out to us today using the inquiry form at the bottom of the screen. We're here to help you every step of the way!",
            questions: [
                {
                    question: "How does the gastric balloon help with weight loss?",
                    answer: "The gastric balloon helps with weight loss by reducing the amount of food you can eat at one time, leading to decreased calorie intake and ultimately weight loss."
                },

                {
                    question: "Who is a suitable candidate for the gastric balloon procedure?",
                    answer: "Suitable candidates for the gastric balloon procedure are individuals who are overweight or obese and have struggled to lose weight through diet and exercise alone. It is often recommended for those with a body mass index (BMI) between 30 and 40."
                },

                {
                    question: "Is the gastric balloon procedure reversible?",
                    answer: "Yes, the gastric balloon procedure is reversible. The balloon can be removed at any time."
                },

                {
                    question: "How long does the gastric balloon stay in the stomach?",
                    answer: "The gastric balloon is typically left in place for 6 or 12 months, depending on the type of balloon used and the individual's weight loss goals."
                },

                {
                    question: "What are the potential side effects of the gastric balloon procedure?",
                    answer: "Some potential side effects of the gastric balloon procedure include nausea, vomiting, abdominal discomfort, and acid reflux. These symptoms usually subside within the first week after balloon placement."
                },

                {
                    question: "Will I need to follow a special diet while the gastric balloon is in place?",
                    answer: "Yes, you will need to follow a special diet while the gastric balloon is in place. This typically includes a liquid or soft food diet for the first few days after balloon placement, followed by a gradual reintroduction of solid foods."
                },

                {
                    question: "How much weight can I expect to lose with the gastric balloon?",
                    answer: "Weight loss results vary from person to person, but on average, individuals can expect to lose between 10% and 15% of their total body weight during the time the balloon is in place."
                },

                {
                    question: "What happens after the gastric balloon is removed?",
                    answer: "After the gastric balloon is removed, it's important to continue following a healthy diet and exercise regimen to maintain weight loss."
                }
            ]
        },
        subTitle: "Understanding Gastric Balloon Procedure: A Non-Surgical Weight Loss Solution",
        content: [
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            },
            {
                type: "block-image",
                imageAltText: "",
                imageName: ""
            },
            {
                type: "unordered-list",
                text: "Here is a typical schedule for your gastric balloon procedure – less than 48 hours:",
                items: [
                    {
                        text: "Day 1: Arrive late afternoon and spend the night in a hotel."
                    },
                    {
                        text: "Day 2: Attend pre-operation consultations and tests. Undergo the gastric balloon procedure. Get discharged from the clinic. Spend the night in the hotel."
                    },
                    {
                        text: "Day 3: Return home – morning flight."
                    }
                ]
            },
            {
                type: "title-3",
                text: "So, what exactly is a gastric balloon procedure? Let's break it down in simple terms:"
            },
            {
                type: "title-4",
                text: "What is a Gastric Balloon?"
            },
            {
                type: "paragraph",
                text: "A gastric balloon is a soft, silicone balloon that is inserted into your stomach through your mouth using an endoscope. Once inside, it's inflated with a sterile saline solution, partially filling your stomach and creating a feeling of fullness. This sensation helps you eat smaller portions and feel satisfied with less food, ultimately aiding in weight loss."
            },
            {
                type: "title-4",
                text: "How Does the Procedure Work?"
            },
            {
                type: "paragraph",
                text: "The gastric balloon procedure is performed by a bariatric surgeon in a non-surgical setting. It typically takes less than 30 minutes and is done under mild sedation to ensure your comfort throughout the process. Once the balloon is in place, you'll be monitored for a short period to ensure there are no complications before being discharged the same day."
            },
            {
                type: "title-4",
                text: "What are the Benefits?"
            },
            {
                type: "paragraph",
                text: "One of the key benefits of the gastric balloon procedure is that it offers significant weight loss results without the need for surgery. It's a reversible option that can jump start your weight loss journey and help you adopt healthier eating habits. Additionally, because it's minimally invasive, recovery time is minimal, allowing you to resume your normal activities shortly after the procedure."
            },
            {
                type: "title-4",
                text: "Is it Right for Me?"
            },
            {
                type: "paragraph",
                text: "The gastric balloon procedure is suitable for individuals who are overweight or obese and have struggled to lose weight through diet and exercise alone. It's often recommended for those who need to lose weight before undergoing more invasive procedures, such as bariatric surgery, or for individuals who prefer a non-surgical approach to weight loss."
            },
            {
                type: "title-4",
                text: "In Conclusion?"
            },
            {
                type: "paragraph",
                text: "If you're ready to take control of your weight and improve your health, the gastric balloon procedure could be the solution you've been searching for. With its non-surgical approach, minimal downtime, and significant weight loss results, it's worth considering as part of your weight loss journey."
            },
            {
                type: "floating-left-image",
                imageAltText: "",
                imageName: ""
            },
            {
                type: "title-3",
                text: "Is Gastric Balloon Right for Me?"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Non-Surgical: The balloon is inserted orally into your stomach, providing a minimally invasive option."
                    },
                    {
                        text: "Quick Recovery: The procedure is quick and efficient, allowing most patients to go home the same day."
                    },
                    {
                        text: "Short downtime – Back to work in 2-5 days."
                    },
                    {
                        text: "Temporary: The balloon remains in the stomach for six or twelve months before it is removed."
                    },
                    {
                        text: "Suitable for Lower BMI: This option is best for patients with a lower body mass index (BMI) who want to lose 20-30 pounds."
                    }
                ]
            },
            {
                type: "paragraph",
                text: "For patients with a very high BMI, the gastric balloon may serve as a preliminary step in weight loss. This can help patients become eligible for more effective procedures such as a gastric balloon."
            }
        ]
    },
    {
        id: 4,
        name: "Gastric Sleeve Surgery",
        title: "Gastric Sleeve Surgery at Allmedica, Poland: A Safe and Effective Choice",
        description:
            "Gastric sleeve surgery is a bariatric procedure designed to help obese individuals achieve significant weight loss for improved health. During the surgery, approximately 70% to 80% of the stomach is removed, leaving behind a smaller pouch shaped like a sleeve. This reduction in stomach size restricts the amount of food that can be consumed, leading to reduced calorie intake and subsequent weight loss.",
        keywords:
            "Allmedica, Gastric Sleeve Surgery, bariatric surgery, weight loss, health improvement, obesity treatment, bariatric procedures, patient care",
        pricePositionIds: [71],
        specializationIds: [3],
        images: [
            { id: useUniqueId(), src: "/img/services/weight_loss_after_gastric_sleeve_1.webp", alt: "Weight loss after gastric sleeve" },
            { id: useUniqueId(), src: "/img/services/weight_loss_after_gastric_sleeve_2.webp", alt: "Weight loss after gastric sleeve" },
            { id: useUniqueId(), src: "/img/services/weight_loss_after_gastric_sleeve_3.webp", alt: "Weight loss after gastric sleeve" }
        ],
        staffIds: [13],
        reviewIds: [1, 2, 3, 4],
        frequentlyAskedQuestions: {
            title: "Gastric Sleeve Surgery FAQ’s",
            subTitle: "Frequently-Asked-Question about Gastric Sleeve Surgery",
            text: "Get all your questions answered with our comprehensive FAQ section on Tummy Tuck Surgery. Learn about the procedure, its benefits, potential risks, and more. If you're curious about our packages or how cosmetic procedures could transform your life, reach out to us today using the inquiry form at the bottom of the screen. We're here to help you every step of the way!",
            questions: [
                {
                    question: "Who Qualifies for Gastric Sleeve Surgery at Allmedica?",
                    answer: `<div>
                            <p>To undergo our facelift surgery, patients must meet specific criteria outlined by our medical team. Generally, candidates for any of our cosmetic surgeries at Allmedica in Poland must meet the following:</p>
                            <ul>
                                <li>Good health</li>
                                <li>Good health</li>
                                <li>Absence of high-risk conditions such as diabetes,  high blood pressure, bleeding disorders, heart diseases, or diagnosed depression.</li>
                            </ul>
                            <small>*Please note: Individuals diagnosed with obesity or those who regularly smoke and consume alcohol are not eligible for cosmetic surgeries.</small>
                        </div>`
                },
                {
                    question: "What is Included in Gastric Sleeve Surgery?",
                    answer: `<div>
                            <ul>
                                <li><b>Expert Surgical Team:</b> Benefit from the expertise of our experience Bariatric Surgeon Prof. Piotr Major, ensuring top-notch care throughout your procedure.</li>
                                <li><b>Comfortable Accommodation:</b> A 2-3 night hospital, providing you with a comfortable and supportive environment for your recovery journey.</li>
                                <li><b>Convenient Transportation:</b> Experience seamless travel with our VIP transfers, ensuring hassle-free access to our facilities.</li>
                                <li><b>Comprehensive Medical Support:</b> Access all necessary laboratory and medical testing as part of your surgery package, ensuring thorough pre-operative preparation and post-operative monitoring.</li>
                                <li><b>Personalized Assistance:</b> Receive dedicated support from a personal case manager who will guide you through every step of your surgical experience, addressing any questions or concerns along the way.</li>
                            </ul>
                        </div>`
                },
                {
                    question: "Is Allmedica a Safe Choice for Gastric Sleeve Surgery?",
                    answer: "Your safety is paramount at Allmedica. We prioritize ongoing training and development for our entire team, ensuring they are equipped with the latest medical knowledge and service expertise. With a focus on trustworthiness, transparency, and responsiveness, we strive to provide an exceptional experience every step of the way. Rest assured, you're in good hands with us."
                },
                {
                    question: "How much weight can I expect to lose after gastric sleeve surgery?",
                    answer: "Weight loss varies from person to person, but on average, patients can expect to lose about 60-70% of their excess weight within the first two years after surgery."
                },
                {
                    question: "What dietary changes will I need to make before and after the procedure?",
                    answer: "Before surgery, you'll have to be on a low-calorie diet to shrink your liver and reduce surgical risks. After surgery, you'll transition to a liquid diet, followed by a gradual reintroduction of soft and then solid foods, focusing on protein and nutrient-rich options."
                },
                {
                    question: "How long does the surgery typically take?",
                    answer: "Gastric sleeve surgery typically takes about 1-2 hours to perform."
                }
            ]
        },
        content: [
            {
                type: "paragraph",
                text: "Bariatric surgery, including gastric sleeve surgery, is a life-saving intervention that facilitates rapid and safe weight loss. By altering the stomach and digestive system, these procedures help patients consume less food and experience reduced hunger."
            },
            {
                type: "paragraph",
                text: "Gastric sleeve surgery is performed laparoscopically, utilizing specialized surgical instruments and a camera inserted through small incisions in the abdomen. This minimally invasive approach allows for quicker recovery times and reduced risk of complications."
            },
            {
                type: "paragraph",
                text: "This type of bariatric surgery can have significant benefits beyond weight loss, including improvement in obesity-related health conditions such as type 2 diabetes, high blood pressure, and sleep apnea. It offers patients a chance to achieve long-term weight management and enhance their overall well-being."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            },
            {
                type: "block-image",
                title: "Vertical Sleeve Gastrectomy",
                imageAltText: "Vertical Sleeve Gastrectomy",
                imageName: "vertical-sleeve-gastrectomy"
            },
            {
                type: "note",
                text: "Please note: The information provided on this website is for informational purposes only. Patients should understand that individual results may vary and should not expect identical outcomes to those depicted on this website. Each patient's experience is unique and influenced by factors such as their specific condition, lifestyle, and post-surgery care. It's important to consult with a healthcare professional to understand the potential outcomes and considerations specific to your situation."
            },
            {
                type: "title-2",
                text: "Gastric Sleeve – Most Effective Bariatric Surgery for Weight Loss"
            },
            {
                type: "infographic",
                title: "Achieve Significant Weight Loss:",
                text: "Experience notable weight loss with gastric sleeve surgery, boasting an average reduction of 60-70% of excess weight within the initial two years post-surgery.",
                imageName: "weight"
            },
            {
                type: "infographic",
                title: "Improve Health:",
                text: "Benefit from improved health outcomes as gastric sleeve surgery can address various obesity-related conditions like type 2 diabetes, high blood pressure, and sleep apnea.",
                imageName: "improve"
            },
            {
                type: "infographic",
                title: "Reduce Hunger:",
                text: "With a reduced stomach size, gastric sleeve surgery aids in controlling food intake and curbing hunger cravings, facilitating better dietary management.",
                imageName: "not-eat"
            },
            {
                type: "infographic",
                title: "Embrace a Better Lifestyle:",
                text: "Enjoy a better quality of life with significant weight loss, leading to increased energy levels, heightened confidence, and greater participation in physical activities.",
                imageName: "yoga"
            },
            {
                type: "infographic",
                title: "Quicker Recovery:",
                text: "Opt for a faster and less painful recovery with gastric sleeve surgery, known for its minimally invasive approach compared to other bariatric procedures.",
                imageName: "recovery"
            },
            {
                type: "title-2",
                text: "Advantages of Gastric Sleeve Surgery at Allmedica:"
            },
            {
                type: "floating-right-image",
                imageName: "weight_loss_after_gastric_sleeve_2_w265",
                imageAltText: "Before and after gastric sleeve surgery."
            },
            {
                type: "title-4",
                text: "Cost-Effectiveness:"
            },
            {
                type: "paragraph",
                text: "Experience significant cost savings with gastric sleeve surgery at Allmedica compared to medical facilities in UK, Irland, USA. Our competitive pricing ensures that you receive top-quality care without breaking the bank."
            },
            {
                type: "title-4",
                text: "Highly-Skilled Bariatric Surgeons:"
            },
            {
                type: "paragraph",
                text: "Entrust your health to our  highly-skilled bariatric surgeon Prof. Piotr Major who is experienced in performing gastric sleeve surgeries. With his expertise and precision, you can feel confident in achieving successful outcomes."
            },
            {
                type: "title-4",
                text: "Fluent English Communication:"
            },
            {
                type: "paragraph",
                text: "Enjoy seamless communication throughout your journey at Allmedica as our team is proficient in English. Clear and effective communication ensures that you understand every aspect of your treatment, providing you with peace of mind."
            },
            {
                type: "title-4",
                text: "Utilization of Modern Techniques:"
            },
            {
                type: "paragraph",
                text: "Benefit from state-of-the-art techniques and cutting-edge technology employed during gastric sleeve surgery. Our commitment to staying abreast of the latest advancements ensures that you receive the most advanced and effective treatment available."
            },
            {
                type: "title-4",
                text: "Personalized Care:"
            },
            {
                type: "paragraph",
                text: "Receive individualized care tailored to your specific needs and preferences throughout your entire treatment process."
            },
            {
                type: "title-4",
                text: "Comprehensive Support:"
            },
            {
                type: "paragraph",
                text: "Access comprehensive support services, including pre-operative consultations, post-operative care, and ongoing guidance to ensure a smooth recovery and long-term success."
            }
        ]
    },
    {
        id: 5,
        name: "Total Hip Replacement",
        title: "Total Hip Replacement in Allmedica, Poland: Restore Your Mobility",
        description:
            "Hip replacement surgery, also known as hip arthroplasty, is a common and highly effective procedure designed to relieve pain and restore function in patients with severely damaged hip joints. This surgery involves removing the damaged sections of the hip joint and replacing them with artificial components. These prosthetic parts mimic the natural function of the hip joint, allowing for improved mobility and reduced pain. Hip replacement is often recommended for patients suffering from severe arthritis, fractures, or other conditions that have not responded to conservative treatments such as medications, physical therapy, or lifestyle modifications.",
        keywords: "Allmedica, Total Hip Replacement, hip surgery, hip replacement, orthopedic surgery, mobility restoration, quality of life improvement",
        pricePositionIds: [76],
        specializationIds: [5],
        images: [],
        staffIds: [4, 8],
        reviewIds: [13, 14, 15, 16],
        frequentlyAskedQuestions: {
            title: "Total Hip Replacement Surgery FAQ’s",
            subTitle: "Frequently-Asked-Question about Total Hip Replacement",
            text: "Get all your questions answered with our comprehensive FAQ section on Total Hip Replacement Surgery. Learn about the procedure, its benefits, potential risks, and more. If you're curious about our packages or how orthopedic procedures could change your life, reach out to us today using the inquiry form at the bottom of the screen. We're here to help you every step of the way!",
            questions: [
                {
                    question: "What is a total hip replacement?",
                    answer: "A total hip replacement is a surgical procedure where the damaged parts of the hip joint are removed and replaced with artificial components made of metal, ceramic, or plastic."
                },
                {
                    question: "Who is a candidate for total hip replacement?",
                    answer: "Candidates are typically those with severe hip pain and disability due to conditions like osteoarthritis, rheumatoid arthritis, or hip fractures, who have not found relief with non-surgical treatments."
                },
                {
                    question: "What are the benefits of hip replacement surgery?",
                    answer: "The benefits include significant pain relief, improved mobility, and a better quality of life, allowing patients to return to daily activities with less discomfort."
                },
                {
                    question: "What are the risks associated with hip replacement surgery?",
                    answer: "As with any procedure, risks include infection, blood clots, dislocation, implant loosening, nerve injury, and differences in leg length. However, complications are rare."
                },
                {
                    question: "How long does the surgery take?",
                    answer: "The surgery typically takes about two hours, depending on the complexity of the case."
                },
                {
                    question: "What type of anesthesia is used during the procedure?",
                    answer: "General anesthesia or spinal anesthesia is commonly used, based on the patient's health."
                },
                {
                    question: "What is the recovery time for hip replacement surgery?",
                    answer: "Most patients stay in the hospital for a 2-3 days and begin physical therapy right after. Full recovery and return to most activities typically take about 3 months."
                },
                {
                    question: "Will I need physical therapy after the surgery?",
                    answer: "Yes, physical therapy is a crucial part of the recovery process to help restore strength, flexibility, and mobility."
                },
                {
                    question: "How long will the artificial hip joint last?",
                    answer: "With advances in materials and surgical techniques, most hip replacements can last 15 to 20 years or more, depending on the patient's activity level and overall health."
                },
                {
                    question: "1Are there any activity restrictions after hip replacement?",
                    answer: "High-impact activities like running or jumping should be avoided to prevent excessive wear on the artificial joint. Low-impact activities like walking, swimming, and cycling are encouraged."
                },
                {
                    question: "1What should I do to prepare for hip replacement surgery?",
                    answer: "Preparing includes managing any chronic conditions, stopping certain medications, arranging for post-surgery help, and ensuring your home is safe and accessible for recovery."
                },
                {
                    question: "1What is the success rate of hip replacement surgery?",
                    answer: "The success rate is very high, with over 90% of patients experiencing significant pain relief and improved function."
                },
                {
                    question: "1Will I need to use assistive devices after surgery?",
                    answer: "Initially, you may need crutches, a walker, or a cane to help with mobility, but most patients gradually wean off these devices as they recover."
                },
                {
                    question: "1Can both hips be replaced at the same time?",
                    answer: "Bilateral hip replacement can sometimes be performed, but it depends on the patient's overall health and the surgeon's recommendation."
                },
                {
                    question: "1What can I do to ensure a successful recovery?",
                    answer: "Follow your surgeon's and physical therapist's instructions, take medications as prescribed, and engage in recommended physical activities."
                }
            ]
        },
        content: [
            {
                type: "paragraph",
                text: "The procedure has a high success rate and can significantly improve the quality of life for many patients. Hip replacement surgery takes about two hours to complete under general or spinal anaesthesia. During the surgery, the surgeon makes an incision over the hip, removes the damaged bone and cartilage, and then implants the prosthetic components. Recovery involves a hospital stay of 2-3 days, followed by 10 days of physical therapy to help restore strength and mobility. Patients can usually resume most of their normal activities within a couple of months. However, high-impact activities may need to be avoided to preserve the integrity of the new joint."
            },
            {
                type: "you-tube-player",
                movie: {
                    id: "puEYQ3jibRQ",
                    title: "Total Hip Replacement in Allmedica Surgery in Poland"
                }
            },
            {
                type: "title-2",
                text: "Advantages of Hip Replacement Surgery"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Improved Mobility: Following hip replacement surgery, patients typically experience significant improvements in their ability to move and perform daily activities."
                    },
                    { text: "Reduced Pain Levels: Hip replacement effectively alleviates arthritis pain, allowing patients to regain comfort and function." },
                    { text: "Restored Joint Flexibility: The procedure helps restore joint flexibility and reduces stiffness in the hip." },
                    { text: "Increased Vitality: Patients often report feeling more energetic and capable of enjoying life after hip replacement." },
                    {
                        text: "Enhanced Social Functioning: Engaging in social activities becomes easier, and maintaining an active lifestyle is achievable post-surgery."
                    }
                ]
            },
            {
                type: "title-2",
                text: "Understanding Total Hip Replacement "
            },
            {
                type: "title-4",
                text: "Anatomy of the Hip"
            },
            {
                type: "unordered-list",
                text: "The hip joint plays a crucial role in daily activities. Here are its key components:",
                items: [
                    { text: "Femur (Thighbone): The upper bone." },
                    { text: "Pelvis (Acetabulum): The socket that receives the femoral head." },
                    { text: "Articular Cartilage: Smooth tissue covering the bone ends." },
                    { text: "Ligaments: Provide stability." },
                    { text: "Muscles: Surrounding the joint and providing strength." },
                    { text: "Synovial Membrane: Lubricates the joint." }
                ]
            },
            {
                type: "title-4",
                text: "Common Causes of Hip Pain"
            },
            {
                type: "ordered-list",
                items: [
                    { text: "Osteoarthritis: Degenerative joint disease affecting cartilage." },
                    { text: "Rheumatoid Arthritis: Inflammation of the synovial membrane." },
                    { text: "Posttraumatic Arthritis: Resulting from injury or trauma." }
                ]
            },
            {
                type: "title-4",
                text: "Total Hip Replacement Procedure"
            },
            {
                type: "ordered-list",
                items: [
                    { text: "Incision: Surgeon makes an incision near the hip joint." },
                    { text: "Bone Preparation: Damaged bone and cartilage are removed." },
                    { text: "Implantation: Replacement parts (prosthesis) are fitted on the femur and acetabulum." }
                ]
            },
            {
                type: "title-4",
                text: "Why Choose Allmedica for Hip Replacement?"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "Fair Pricing: Transparent cost structure." },
                    { text: "No Waiting Lists: Expedited services." },
                    { text: "Quality and Professionalism: Upheld medical standards." },
                    { text: "Global Patients: Trusted medical tourism destination." }
                ]
            },
            {
                type: "title-2",
                text: "Advantages of Hip Replacement Surgery"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Improved Mobility: Following hip replacement surgery, patients typically experience significant improvements in their ability to move and perform daily activities."
                    },
                    { text: "Reduced Pain Levels: Hip replacement effectively alleviates arthritis pain, allowing patients to regain comfort and function." },
                    { text: "Restored Joint Flexibility: The procedure helps restore joint flexibility and reduces stiffness in the hip." },
                    { text: "Increased Vitality: Patients often report feeling more energetic and capable of enjoying life after hip replacement." },
                    {
                        text: "Enhanced Social Functioning: Engaging in social activities becomes easier, and maintaining an active lifestyle is achievable post-surgery."
                    }
                ]
            }
        ]
    },
    {
        id: 6,
        name: "Knee Replacement",
        title: "Total Knee Replacement in Allmedica, Poland: Restore Your Mobility",
        description:
            "At Allmedica Surgery in Poland, we specialize in total knee replacement—a safe and effective procedure that can transform your quality of life. Whether you’re struggling with chronic knee pain due to arthritis or injury, our expert surgeons are here to help.",
        specializationIds: [4],
        images: [],
        pricePositionIds: [77],
        staffIds: [4, 8],
        reviewIds: [17, 18, 19, 20, 21, 22],
        frequentlyAskedQuestions: {
            title: "Total Knee Replacement Surgery FAQ’s",
            subTitle: "Frequently-Asked-Question about total Knee Replacement",
            text: "Get all your questions answered with our comprehensive FAQ section on Total Knee Replacement Surgery. Learn about the procedure, its benefits, potential risks, and more. If you're curious about our packages or how orthopedic procedures could change your life, reach out to us today using the inquiry form at the bottom of the screen. We're here to help you every step of the way!",
            questions: [
                {
                    question: "When is the right time to have a knee replacement?",
                    answer: "The right time for a knee replacement is when you have tried other treatments such as lifestyle changes, medication, physical therapy, and injections without sufficient relief. If knee pain is affecting your quality of life and other treatments are not helping, it may be time to consider surgery1."
                },
                {
                    question: "Can I avoid surgery?",
                    answer: "Nonsurgical treatments like physical therapy, weight loss, anti-inflammatory medication, steroid injections, and alternative treatments like acupuncture are often encouraged before considering surgery. If these do not manage the symptoms and your quality of life is compromised, surgery might be the best option."
                },
                {
                    question: "What happens during surgery, and how long does it take?",
                    answer: "During surgery, the surgeon makes an incision over the front of your knee to expose the damaged area. They replace the damaged cartilage and bone with new metal and plastic components to form an artificial joint. The procedure typically takes 1 to 2 hours."
                },
                {
                    question: "What is an artificial knee made of?",
                    answer: "Artificial knee implants are made of metal and medical-grade plastic called polyethylene. They can be attached to the bone with bone cement or a cement-free approach that allows the bone to grow onto them."
                },
                {
                    question: "Should I worry about anesthesia?",
                    answer: "While any operation involving anesthesia has risks, severe complications are rare. Options for anesthesia during a total knee replacement include general anesthesia or spinal/epidural anesthesia."
                },
                {
                    question: "How long is the recovery process after a total knee replacement?",
                    answer: `<p>The recovery process after a total knee replacement can vary from person to person, but here is a general timeline:</p>
                        <p><b>Day 1:</b> Rehabilitation typically begins immediately after surgery. Patients usually leave the hospital in 2-3 days. Physical therapy starts within the first 24 hours to help with movement and prevent blood clots.</p>
                        <p><b>First Week:</b> Significant rest is required, but gentle knee exercises are encouraged to aid healing. Walking with assistance every hour is recommended to prevent blood clots, and keeping the knee elevated can help with swelling.</p>
                        <p><b>Weeks 3-6:</b> By the third week, many patients see progress in healing and may need less pain medication. It’s important to watch for signs of complications such as infection or blood clots. Most people can walk without an assistive device after 3 weeks and drive after 4-6 weeks.</p>
                        <p><b>Weeks 7-11:</b> Recovery continues with physical therapy and increased activity levels. Patients should continue to follow the surgeon and physical therapist’s instructions.</p>
                        <p><b>Week 12 and Beyond:</b> Most activities can be resumed, although it may take 6 months to a year to fully recover and experience the total benefits of the surgery.</p>`
                },
                {
                    question: "What exercises can I do during the recovery process?",
                    answer: `<p>During the recovery process after a total knee replacement, it’s important to engage in exercises that will help restore strength, mobility, and flexibility to your knee. Here are some exercises that are generally recommended:</p>
                        <ul>
                            <li>Ankle Pumps: Move your foot up and down by contracting your calf and shin muscles. This helps with circulation and can be done frequently throughout the day.</li>
                            <li>Quad Sets: Tighten your thigh muscle and try to straighten your knee. Hold for 5 to 10 seconds and repeat. This helps strengthen your thigh muscles.</li>
                            <li>Straight Leg Raises: With your knee straightened, lift your leg several inches off the bed or floor. Hold for a few seconds and slowly lower. This exercise strengthens your thigh and improves knee stability.</li>
                            <li>Knee Straightening Exercises: Place a small rolled towel under your heel and try to straighten your knee fully. Hold for a few seconds and repeat. This helps in achieving full knee extension.</li>
                            <li>Bed-Supported Knee Bends: Slide your foot towards your buttocks, bending your knee while keeping your heel on the bed. This helps increase knee flexibility.</li>
                            <li>Sitting Knee Flexion: While sitting, slide your heel forward and backward to increase knee bending range of motion.</li>
                            <li>Standing Heel and Toe Raises: Stand and raise up on your toes, then lift your toes off the ground. This helps with balance and ankle strength.</li>
                            <li>Standing Knee Flexion: Stand on your good leg and bend your operated leg behind you to improve flexibility.</li>
                            <li>Knee Extension Stretch: Place a towel roll under your ankle and relax to stretch the front of your knee.</li>
                        </ul>
                        <p>Your physical therapist will provide you with a specific exercise plan tailored to your individual needs and recovery progress. Remember, the goal is to gradually increase your knee’s strength and range of motion without causing undue stress or pain.</p>`
                },
                {
                    question: "What are some precautions to take after knee replacement surgery?",
                    answer: `<p>After a total knee replacement surgery, it’s crucial to follow certain precautions to ensure a smooth and safe recovery. Here are some key precautions to consider:</p>
                        <ol>
                            <li><span>Wound Care:</span>
                                <ul>
                                    <li>Keep the surgical area clean and dry.</li>
                                    <li>Follow your surgeon’s instructions on how to care for your wound.</li>
                                    <li>Avoid applying creams, lotions, or heat directly to the incision area.</li>
                                </ul>
                            </li>
                            <li><span>Blood Clot Prevention:</span>
                                <ul>
                                    <li>Stay active and move your legs regularly to promote circulation.</li>
                                    <li>Wear compression stockings if recommended by your doctor.</li>
                                    <li>Follow your doctor’s advice regarding blood-thinning medications.</li>
                                </ul>
                            </li>
                            <li><span>Physical Activity:</span>
                                <ul>
                                    <li>Gradually increase your activity level as advised by your healthcare team.</li>
                                    <li>Avoid high-impact activities that could stress your new joint.</li>
                                    <li>Use assistive devices like a walker or cane until you regain strength and balance.</li>
                                </ul>
                            </li>
                            <li><span>Home Safety:</span>
                                <ul>
                                    <li>Remove tripping hazards such as loose rugs and electrical cords.</li>
                                    <li>Install safety bars in the bathroom if needed.</li>
                                    <li>Ensure that your living space is easy to navigate with a walker or crutches.</li>
                                </ul>
                            </li>
                            <li><span>Medication Management:</span>
                                <ul>
                                    <li>Take pain medication as prescribed to manage discomfort.</li>
                                    <li>Set alarms to remind you to take your medication on time.</li>
                                    <li>Keep a log of your medication schedule to avoid missing doses.</li>
                                </ul>
                            </li>
                            <li><span>Avoiding Certain Movements:</span>
                                <ul>
                                    <li>Do not pivot or twist on your operated knee.</li>
                                    <li>Avoid kneeling or squatting.</li>
                                    <li>Be cautious when turning to prevent stress on your new knee</li>
                                </ul>
                            </li>
                            <li><span>Proper Sitting and Sleeping Positions:</span>
                                <ul>
                                    <li>Sit in chairs with a seat height equal to or higher than your operated knee.</li>
                                    <li>Avoid sitting for more than one hour at a time without getting up to move.</li>
                                    <li>Sleep on your back or the non-operated side with a pillow between your legs for support.</li>
                                </ul>
                            </li>
                        </ol>`
                }
            ]
        },
        content: [
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            },
            {
                type: "you-tube-player",
                movie: {
                    id: "mfiM64YEP5A",
                    title: "Total knee replacement surgery in Allmedica Surgery"
                }
            },
            {
                type: "title-2",
                text: "Understanding Total Knee Replacement"
            },
            {
                type: "title-4",
                text: "Anatomy of the Knee"
            },
            {
                type: "unordered-list",
                text: "The knee is the largest joint in your body, crucial for everyday activities. It consists of:",
                items: [
                    { text: "Thighbone (Femur): The upper bone." },
                    { text: "Shinbone (Tibia): The lower bone." },
                    { text: "Kneecap (Patella): Protects the joint." },
                    { text: "Articular Cartilage: Smooth tissue covering bone ends." },
                    { text: "Menisci: Shock-absorbing wedges." },
                    { text: "Ligaments: Provide stability." },
                    { text: "Thigh Muscles: Give strength." },
                    { text: "Synovial Membrane: Lubricates the joint." }
                ]
            },
            {
                type: "title-4",
                text: "Causes of Knee Pain"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "Osteoarthritis: Wear-and-tear arthritis affecting cartilage." },
                    { text: "Rheumatoid Arthritis: Inflammation of the synovial membrane." },
                    { text: "Posttraumatic Arthritis: Resulting from injury." }
                ]
            },
            {
                type: "title-4",
                text: "Total Knee Replacement Procedure"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "Incision: Surgeon makes a front knee incision." },
                    { text: "Bone Preparation: Damaged bone and cartilage removed." },
                    { text: "Implantation: Replacement parts (prosthesis) fitted on thighbone, shinbone, and kneecap." }
                ]
            },
            {
                type: "title-4",
                text: "Why Choose Allmedica?"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "Fair Pricing: Transparent costs." },
                    { text: "No Waiting Lists: Expedited services." },
                    { text: "Quality and Professionalism: Upheld standards." },
                    { text: "Global Patients: Trusted medical tourism destination." }
                ]
            },
            {
                type: "block-image",
                imageName: "",
                imageAltText: ""
            },
            {
                type: "note",
                text: "Please note: The information provided on this website is for informational purposes only. Patients should understand that individual results may vary and should not expect identical outcomes to those depicted on this website. Each patient's experience is unique and influenced by factors such as their specific condition, lifestyle, and post-surgery care. It's important to consult with a healthcare professional to understand the potential outcomes and considerations specific to your situation."
            },
            {
                type: "title-2",
                text: "Advantages of Knee Replacement Surgery"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Improved Mobility: After knee replacement, patients experience significant improvements in their ability to move and perform daily activities."
                    },
                    {
                        text: "Reduced Pain Levels: Knee replacement can effectively alleviate arthritis pain, allowing patients to regain comfort and function."
                    },
                    { text: "Reduced Joint Stiffness: Knee replacement helps restore joint flexibility and reduces stiffness." },
                    { text: "Increased Vitality: Patients report feeling more energetic and capable of enjoying life again." },
                    {
                        text: "Enhanced Social Functioning: Patients often find engaging in social activities easier and maintaining an active lifestyle post-surgery."
                    }
                ]
            }
        ]
    },
    {
        id: 7,
        name: "Abdominoplasty",
        title: "Tummy Tuck Surgery (abdominoplasty) in Allmedica, Poland",
        description:
            "A tummy tuck, also known as abdominoplasty, is a surgical procedure designed to flatten the abdominal region by removing excess fat and skin while tightening the abdominal wall muscles. Variations of this procedure include Fleur-de-Lis abdominoplasty which is slightly more complex.",
        pricePositionIds: [20, 21, 22, 23, 24],
        specializationIds: [5],
        images: [{ id: useUniqueId(), src: "/img/services/abdominal_and_sides_liposuction.webp", alt: "Abdominal and sides liposuction" }],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "paragraph",
                text: "A tummy tuck, also known as abdominoplasty, is a surgical procedure designed to flatten the abdominal region by removing excess fat and skin while tightening the abdominal wall muscles. Variations of this procedure include Fleur-de-Lis abdominoplasty which is slightly more complex."
            },
            {
                type: "paragraph",
                text: "The skin and muscle tissue in the abdomen can naturally stretch and contract with changes in body shape. However, in cases of extreme weight fluctuations or pregnancy, the skin and muscles may become overstretched, leading to issues such as stretch marks and weakened abdominal muscles. Tummy tuck surgery offers a solution to restore a smoother, firmer abdominal contour for those affected by these concerns."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            },
            {
                type: "unordered-list",
                text: "This type of bariatric surgery can have significant benefits beyond weight loss, including improvement in obesity-related health conditions such as type 2 diabetes, high blood pressure, and sleep apnea. It offers patients a chance to achieve long-term weight management and enhance their overall well-being.",
                items: [
                    {
                        text: "Day 1: Arrive late afternoon and spend the night in a hotel. Rest."
                    },
                    {
                        text: "Day 2: Attend pre-operation consultations and tests. Undergo the Tummy Tuck surgery. Spend the night in the clinic."
                    },
                    {
                        text: "Day 3: Get discharged from the clinic. Spend the night in the hotel."
                    },
                    {
                        text: "Day 4: Rest and recover. Spend the night in the hotel."
                    },
                    {
                        text: "Day 5: Follow-up consultation. Return home."
                    }
                ]
            },
            {
                type: "block-image",
                title: "",
                text: "",
                imageName: "",
                imageAltText: "Tummy tuck variations"
            },
            {
                type: "note",
                text: "Please note: The information provided on this website is for informational purposes only. Patients should understand that individual results may vary and should not expect identical outcomes to those depicted on this website. Each patient's experience is unique and influenced by factors such as their specific condition, lifestyle, and post-surgery care. It's important to consult with a healthcare professional to understand the potential outcomes and considerations specific to your situation."
            },
            {
                type: "title-3",
                text: "Experience the Best with VASER Liposuction"
            },
            {
                type: "floating-left-image",
                title: "",
                imageAltText: "Vaser lipo logi graphic",
                imageName: ""
            },
            {
                type: "unordered-list",
                text: "At Allmedica in Poland we use VASER liposuction, the leading technology for body contouring and fat removal. This advanced ultrasound technology offers precision and results for a smoother, more sculpted appearance.",
                items: [
                    {
                        text: "<b>Enhanced Precision:</b> Achieve precise sculpting and contouring for natural-looking results."
                    },
                    {
                        text: "<b>Less Trauma and Discomfort:</b> Reduced impact on surrounding tissues leads to less bruising and swelling."
                    },
                    {
                        text: "<b>Faster Recovery:</b> Minimal downtime and a quicker return to daily activities."
                    },
                    {
                        text: "<b>Skin Tightening:</b> Stimulates collagen production for improved skin elasticity and a tighter appearance."
                    },
                    {
                        text: "<b>Versatility and Safety:</b> Effective on various body areas for different patient needs, with a proven safety record."
                    }
                ]
            },
            {
                type: "paragraph",
                text: "Choose VASER LIPO for the best in body contouring. Contact us today."
            }
        ],
        frequentlyAskedQuestions: {
            title: "Tummy Tuck Surgery FAQ’s",
            subTitle: "Frequently-Asked-Question about Abdominoplasty",
            text: "Get all your questions answered with our comprehensive FAQ section on Tummy Tuck Surgery. Learn about the procedure, its benefits, potential risks, and more. If you're curious about our packages or how cosmetic procedures could transform your life, reach out to us today using the inquiry form at the bottom of the screen. We're here to help you every step of the way!",
            questions: [
                {
                    question: "Who Qualifies for Facelift Surgery at Allmedica?",
                    answer: `<div>
                            <p>To undergo our facelift surgery, patients must meet specific criteria outlined by our medical team. Generally, candidates for any of our cosmetic surgeries at Allmedica in Poland must meet the following:</p>
                            <ul>
                                <li>Good health</li>
                                <li>Absence of high-risk conditions such as diabetes, high blood pressure, bleeding disorders, heart diseases, or diagnosed depression.</li>
                            </ul>
                            <small>*Please note: Individuals diagnosed with obesity or those who regularly smoke and consume alcohol are not eligible for cosmetic surgeries.</small>
                        </div>`
                },
                {
                    question: "What to Expect After Tummy Tuck Surgery?",
                    answer: `<p>Following your tummy tuck surgery, you will typically remain in the hospital for 1-2 <b>nights</b> to ensure proper healing. Additionally, you'll need to stay in Poland for an extra four to nine days after being discharged from the hospital to manage the surgical drains.</p>
                    <p>For regular tummy tucks, the surgical drains are typically removed five days after surgery. Stitches will be removed gradually over the period of two weeks following the surgery. Please keep in mind that if you plan to return home before two weeks, some stitches may need to be removed after your departure from Poland.</p>`
                },
                {
                    question: "Benefits and Risks of Tummy Tuck Surgery?",
                    answer: `<div>
                        <p>Benefits of Tummy Tuck Surgery:</p>
                        <ul>
                            <li>Improved appearance</li>
                            <li>Boosted self-image</li>
                            <li>Improved self-esteem</li>
                            <li>Tightened and firmer abdomen</li>
                            <li>Reduction in the appearance of stretch marks</li>
                        </ul>
                        <p>Possible Complications of Tummy Tuck Surgery:</p>
                        <p>While tummy tuck surgery offers numerous benefits, it's essential to remember that it is not a substitute for traditional weight loss methods, healthy eating habits, or regular exercise. Patients must maintain a proper diet and exercise regimen to sustain the results of the procedure effectively. Therefore, it's crucial for patients to have completed any plans for significant weight loss and to avoid future pregnancies to preserve the benefits of the surgery.</p>
                        <p>As with any major surgical procedure, tummy tuck surgery carries inherent risks, including: bleeding, potential reaction to anesthesia, temporary pain and swelling, risk of infection, bruising, numbness, fatigue.</p>
                    </div>`
                },
                {
                    question: "What to Expect During Tummy Tuck Surgery?",
                    answer: `<div>
                        <p>During your tummy tuck surgery, you may undergo one of two different types of procedures: Regular Tummy Tuck and Fleur-de-Lis Tummy Tuck.</p>
                        <p>For the regular tummy tuck procedure, your surgeon will make several incisions, with the most significant being a long, curved incision that extends under the lower abdomen from hip to hip, resembling a smile, and around the navel. The skin is then carefully separated from the abdominal muscles, and any excess skin is trimmed away. The vertical abdominal muscles, which may have become separated due to overstretching, are then stitched together to create a tighter appearance, often resulting in a reduction of the waistline. In some cases, a small amount of liposuction may be used to remove stubborn pockets of fat. Finally, the incisions are closed using surgical stitches, and if necessary, the navel is repositioned.</p>
                        <p>During a Fleur-de-Lis Tummy Tuck procedure, your surgeon will typically begin with an initial incision along the lower abdomen, similar to the incision made in a regular tummy tuck. However, in addition to this horizontal incision, a vertical incision is also made along the midline of the abdomen, resembling the shape of a "Fleur-de-Lis" symbol.</p>
                        <p>Following the incisions, the excess skin is carefully removed, similar to the process in a regular tummy tuck. The abdominal muscles may be tightened, and any underlying fat may be addressed through liposuction if necessary.</p>
                        <p>Once the excess skin and fat have been addressed, the incisions are closed using surgical sutures. This procedure aims to achieve significant contouring of the abdomen, particularly in cases where there is excess skin and tissue both horizontally and vertically.</p>
                    </div>`
                },
                {
                    question: "How Long Will My Recovery Be After Tummy Tuck Surgery?",
                    answer: `<div>
                        <p>Recovering from tummy tuck surgery typically involves a slow and gradual process with minimal pain. During the initial days following surgery, you may experience discomfort and swelling in the treated area. Our medical team will provide you with a prescription for a pain medication to manage any discomfort. We encourage gentle activities such as light walking to help reduce swelling and prevent the formation of blood clots in the legs.</p>
                        <p>Patients are advised to wear compression garments for the first six weeks following tummy tuck surgery. It's common to experience numbness in the treated area for up to six months. Exercise and lifting can typically be resumed within 6-8 weeks, while most individuals return to work approximately three weeks post-surgery.</p>
                        <p>Scarring from the procedure will gradually fade over about a year, with the size and location of scars depending on the extent of the surgery. For instance, scars may extend from hip to hip for a regular tummy tuck. It's important to note that it is recommended to avoid becoming pregnant after undergoing a tummy tuck or abdominoplasty.</p>
                        <p>Once your recovery is complete, you can expect your abdomen area to feel firmer and tighter, with a slimmer waistline. Depending on the type of procedure performed, you may also notice improvements in the appearance of your buttocks and thighs, particularly with a circular tummy tuck.</p>
                    </div>`
                },
                {
                    question: "What’s Included in Your Tummy Tuck Surgery?",
                    answer: `<div>
                        <p>Our comprehensive tummy tuck surgery covers everything you need for a smooth and successful procedure.</p>
                        <p>This includes:</p>
                        <ul>
                            <li>A dedicated Case Manager to guide you through the pre- and post tummy tuck experience</li>
                            <li>Pre-tummy tuck consultation if needed</li>
                            <li>Medical tests conducted before the surgery to ensure your safety and suitability for the procedure.</li>
                            <li>The surgery itself, which encompasses all aspects such as personnel, medications, and any necessary equipment.</li>
                            <li>1-2 nights of accommodation at our clinic, where you'll receive round-the-clock medical care, including medications and meals.</li>
                        </ul>
                        <p>At our facility, we maintain the highest standards of medical care and patient comfort. Our dedication to excellence is reflected in our use of cutting-edge technology and services, including state-of-the-art operating rooms and comprehensive internal diagnostic services across all medical specialties. Your well-being is our top priority, and we are committed to providing you with the best possible experience throughout your tummy tuck surgery journey.</p>
                    </div>`
                },
                {
                    question: "Do You Provide Transfers and Accommodation?",
                    answer: `<div>
                        <p>Yes, we offer a convenient solution through our "All-inclusive" package designed specifically for our patients. This package, available for an additional charge, includes:</p>
                        <ul>
                            <li><b>Accommodation with Breakfast:</b> You'll stay at a hotel just 5 minutes from the clinic.</li>
                            <li><b>Transfers Included:</b> All transfers to and from the airport, as well as between the hotel and the clinic, are covered.</li>
                        </ul>
                        <p>Additionally, you'll have a <b>VIP Host</b> with you from the moment you arrive until you leave. Your host will make sure you have everything you need to feel comfortable and will even take care of your prescriptions.</p>
                        <p>The cost of the package varies depending on how long you stay. If you plan to bring a companion, please let us know. We'll be happy to help arrange everything for both of your stays.</p>
                        <p>We want you to feel relaxed and comfortable during your stay. At Allmedica in Poland, we offer top-quality service and a pleasant experience from start to finish. Our team of highly skilled experts will take care of you throughout your journey. Choosing Allmedica for your cosmetic surgery is the right decision for a safe and satisfying experience. Let us know how we can assist you!</p>
                    </div>`
                },
                {
                    question: "Why Choose Allmedica for My Tummy Tuck Surgery in Poland?",
                    answer: `<div>
                        <ul>
                            <li><b>Years of Established Excellence:</b> With over 170,000 patients served since 2012 across 15 branches, Allmedica boasts extensive experience and expertise in general practice.</li>
                            <li><b>Adherence to Stringent Standards:</b> As a member of the European Union, Allmedica adheres to a strict sanitary regime, followed in Europe. Your safety and well-being are our top priorities, and we maintain the highest standards of cleanliness and hygiene.</li>
                            <li><b>Continuous Professional Development:</b> Our specialists continually enhance their skills and knowledge through participation in numerous workshops and seminars led by renowned innovators in their respective fields. This dedication to ongoing learning ensures that we stay at the forefront of medical advancements, delivering the latest and most effective treatments to our patients.</li>
                            <li><b>Positive Patient Feedback:</b> Our commitment and professionalism are reflected in the positive feedback we receive from our patients. At Allmedica, we take pride in our ability to approach each patient individually, providing personalized care and attention to address your unique needs and concerns.</li>
                            <li><b>Comprehensive Care for Your Well-being:</b> With Allmedica, you can expect comprehensive care that prioritizes your health and well-being. From your initial consultation to post-operative support, our dedicated team is here to guide you through every step of your surgery journey, ensuring a smooth and successful experience.</li>
                        </ul>
                    </div>`
                },
                {
                    question: "How to prepare for Surgery Under General Anaesthesia?",
                    answer: `<div>
                        <p>To ensure a smooth surgery and recovery, please follow these pre-op instructions:</p>
                        <ul>
                                <li><b>Fasting:</b> Do not eat or drink anything for 8 hours before the operation. This is crucial for your safety during anaesthesia.</li>
                                <li><b>Comfortable Clothing:</b> Wear comfortable, loose-fitting clothing to the surgery. This will make it easier to change and more comfortable during recovery.</li>
                                <li><b>Shower:</b> Take a shower on the morning of the surgery to help you relax and prepare.</li>
                                <li><b>No Makeup, Pedicure, or Manicure:</b> Please do not wear makeup and avoid having a manicure or pedicure on the day of surgery.</li>
                                <li><b>Remove Metal Objects:</b> Take off any metal objects such as piercings, jewellery, and hairpins before the operation.</li>
                                <li><b>Herbal and Supportive Products:</b> Avoid herbal teas, green tea, and other supportive products for at least 10 days before and after surgery. Green tea can increase the risk of bleeding.</li>
                                <li><b>Health Concerns:</b> Let us know if you have any health issues like a cold or flu. This could affect your surgery and may lead to a cancellation.</li>
                                <li><b>Medications:</b> Inform us if you take blood pressure, heart, diabetes, or thyroid medications. Bring your medication with you on the day of surgery. Also, avoid taking vitamin E.</li>
                                <li><b>Rest:</b> Get plenty of rest before surgery. Avoid any strenuous activities.</li>
                                <li><b>No Smoking or Alcohol:</b> Stop smoking and drinking alcohol one week before surgery.</li>
                        </ul>
                    </div>`
                }
            ]
        }
    },
    {
        id: 8,
        name: "Brasilian Butt Lift",
        title: "Brasilian Butt Lift (BBL)",
        description: "", //! todo
        pricePositionIds: [31],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Brazilian Butt Lift (BBL): What You Need to Know."
            },
            {
                type: "unordered-list",
                text: "A Brazilian Butt Lift (BBL) is a popular cosmetic procedure that enhances the shape and size of your buttocks using your own body fat. Here's what it involves:",
                items: [
                    {
                        text: "<b>Fat Removal:</b> First, the surgeon removes fat from areas where you might have excess, like your abdomen, thighs, or back. This is done using liposuction, a process that uses a thin tube to suction out the fat."
                    },
                    {
                        text: "<b>Fat Purification:</b> The removed fat is then processed to remove impurities, making it ready for transfer."
                    },
                    {
                        text: "<b>Fat Transfer:</b> The purified fat is injected into specific areas of your buttocks to create a fuller and more rounded shape."
                    }
                ]
            },
            {
                type: "title-2",
                text: "Benefits:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "<b>Natural-Looking Results:</b> The procedure uses your own body fat, so the results look and feel natural."
                    },
                    {
                        text: "<b>Body Contouring:</b> Not only does the BBL enhance your buttocks, but it can also improve the contours of the areas from where the fat was removed."
                    },
                    {
                        text: "<b>Long-Lasting:</b> The results can last for many years with proper care."
                    }
                ]
            },
            {
                type: "title-2",
                text: "What to expect:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "<b>Recovery:</b> You'll need to avoid sitting directly on your buttocks for about two weeks to allow the transferred fat to settle properly. You will also need to wear a compression garment."
                    },
                    {
                        text: "<b>Results:</b> You'll start to see the full results in a few months as the swelling goes down."
                    },
                    {
                        text: "<b>Long-Lasting:</b> The results can last for many years with proper care."
                    }
                ]
            },
            {
                type: "title-2",
                text: "Is a BBL Right for You?"
            },
            {
                type: "unordered-list",
                text: "A BBL might be a good option if you want to:",
                items: [
                    {
                        text: "Enhance the shape and size of your buttocks"
                    },
                    {
                        text: "Use your own body fat instead of implants"
                    },
                    {
                        text: "<mprove the contour of your body"
                    }
                ]
            },
            {
                type: "floating-left-image",
                imageAltText: "", // todo
                imageName: "" // todo
            },
            {
                type: "unordered-list",
                text: "Here is a typical schedule for your Brazilian Butt Lift Surgery:",
                items: [
                    {
                        text: "Day 1: Arrive late afternoon and spend the night in a hotel. Rest."
                    },
                    {
                        text: "Day 2: Attend pre-operation consultations and tests. Undergo the Brazilian Butt Lift surgery. Spend the night in the clinic."
                    },
                    {
                        text: "Day 3: Get discharged from the clinic. Spend the night in the hotel."
                    },
                    {
                        text: "Day 4: Rest and recover. Spend the night in the hotel."
                    },
                    {
                        text: "Day 5: Follow-up consultation. Return home."
                    }
                ]
            }
        ],
        frequentlyAskedQuestions: {
            title: "Brazilian Butt Lift Surgery FAQ’s",
            subTitle: "Frequently-Asked-Question about Brazilian Butt Lift Surgery",
            text: "Get all your questions answered with our comprehensive FAQ section on Brazilian Butt Lift Surgery. Learn about the procedure, its benefits, potential risks, and more. If you're curious about our packages or how cosmetic procedures could transform your life, reach out to us today using the inquiry form at the bottom of the screen. We're here to help you every step of the way!",
            questions: [
                {
                    question: "What is a Brazilian Butt Lift (BBL)?",
                    answer: "A Brazilian Butt Lift is a cosmetic procedure that enhances the shape and size of your buttocks by using fat harvested from other areas of your body. The fat is purified and then injected into your buttocks to create a fuller, rounder appearance."
                },

                {
                    question: "Am I a good candidate for BBL?",
                    answer: "Good candidates for BBL are typically in good health, have realistic expectations, and have enough excess fat in other areas of their body for fat transfer. It's important to discuss your goals and medical history with a coordinator."
                },
                {
                    question: "How long does the BBL procedure take?",
                    answer: "The procedure usually takes around 3 to 5 hours, depending on the amount of fat being transferred and the complexity of the case."
                },
                {
                    question: "What can I expect during recovery?",
                    answer: "Recovery varies for each individual. You'll need to avoid sitting directly on your buttocks for about two weeks to allow the fat to settle. You may experience some bruising, swelling, and discomfort, which can be managed with prescribed medication."
                },
                {
                    question: "When will I see the final results?",
                    answer: "You'll see some immediate changes, but it may take a few months for the full results to appear as swelling decreases and the transferred fat settles."
                },
                {
                    question: "How long do the results last?",
                    answer: "The results of a BBL can be long-lasting, but factors like aging and weight fluctuations can impact the appearance over time. Maintaining a stable weight and healthy lifestyle can help preserve the results."
                },
                {
                    question: "Are there risks or complications?",
                    answer: "As with any surgery, there are potential risks such as infection, scarring, and asymmetry. Discuss the potential risks and complications with your surgeon during your pre-operation consultation to make an informed decision."
                },
                {
                    question: "Can I combine BBL with other procedures?",
                    answer: "Yes, many patients choose to combine BBL with other procedures such as liposuction, tummy tuck, or breast augmentation for a more comprehensive body contouring plan."
                },
                {
                    question: "Do You Provide Transfers and Accommodation?",
                    answer: `<div><p>Yes, we offer a convenient solution through our "All-inclusive" package designed specifically for our patients. This package, available for an additional charge, includes:</p>
                        <li><b>Accommodation with Breakfast:</b> You'll stay at a hotel just 5 minutes from the clinic.</li>
                        <li><b>Transfers Included:</b> All transfers to and from the airport, as well as between the hotel and the clinic, are covered.</li>
                        <p>Additionally, you'll have a <b>VIP Host</b> with you from the moment you arrive until you leave. Your host will make sure you have everything you need to feel comfortable and will even take care of your prescriptions.</p>
                        <p>The cost of the package varies depending on how long you stay. If you plan to bring a companion, please let us know. We'll be happy to help arrange everything for both of your stays.</p>
                        <p>We want you to feel relaxed and comfortable during your stay. At Allmedica in Poland, we offer top-quality service and a pleasant experience from start to finish. Our team of highly skilled experts will take care of you throughout your journey. Choosing Allmedica for your cosmetic surgery is the right decision for a safe and satisfying experience. Let us know how we can assist you!</p>
                    </div>`
                },
                {
                    question: "10.	Why Choose Allmedica for My Tummy Tuck Surgery in Poland?",
                    answer: `<ul>
                        <li><b>Years of Established Excellence:</b> With over 170,000 patients served since 2012 across 15 branches, Allmedica boasts extensive experience and expertise in general practice.</li>
                        <li><b>Adherence to Stringent Standards:</b> As a member of the European Union, Allmedica adheres to a strict sanitary regime, followed in Europe. Your safety and well-being are our top priorities, and we maintain the highest standards of cleanliness and hygiene.</li>
                        <li><b>Continuous Professional Development:</b> Our specialists continually enhance their skills and knowledge through participation in numerous workshops and seminars led by renowned innovators in their respective fields. This dedication to ongoing learning ensures that we stay at the forefront of medical advancements, delivering the latest and most effective treatments to our patients.</li>
                        <li><b>Positive Patient Feedback:</b> Our commitment and professionalism are reflected in the positive feedback we receive from our patients. At Allmedica, we take pride in our ability to approach each patient individually, providing personalized care and attention to address your unique needs and concerns.</li>
                        <li><b>Comprehensive Care for Your Well-being:</b> With Allmedica, you can expect comprehensive care that prioritizes your health and well-being. From your initial consultation to post-operative support, our dedicated team is here to guide you through every step of your surgery journey, ensuring a smooth and successful experience.</li>
                    </ul>`
                }
            ]
        }
    },
    {
        id: 9,
        name: "Blepharoplasty",
        title: "Blepharoplasty Surgery (eyelid Surgery) in Allmedica, Poland",
        subTitle: "Enhance Your Eyes with Eyelid Lift Surgery",
        description:
            "Your eyes are one of the most expressive parts of your face, but sometimes they might not be showing how you truly feel. If your eyes look tired or sad, or if you seem older than you are, it might be due to drooping eyelids or puffiness. This can happen because of aging, genetics, skin type, and environmental factors like sun exposure.",
        pricePositionIds: [45, 46, 47, 48, 49],
        specializationIds: [5],
        images: [{ id: useUniqueId(), src: "/img/services/upper_eyelid_surgery_with_eyebrow_lift_1.webp", alt: "Upper eyelid surgery with eyebrow lift" }],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "paragraph",
                text: "Our Eyelid Lift Surgery, also known as Blepharoplasty, can be done on the upper eyelids, lower eyelids, or both. This surgery can help lift and refresh your eyes, making you look more alert and youthful. Let us help you bring your natural, vibrant eyes back to life!"
            },
            {
                type: "title-3",
                text: "Here is a typical schedule for your Blepharoplasty Surgery – in less than 48 hours:"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "Day 1: Arrive late afternoon and spend the night in a hotel. Rest." },
                    {
                        text: "Day 2: Attend pre-operation consultations and tests. Undergo the blepharoplasty surgery. Get discharged from the clinic. Spend the night in the hotel."
                    },
                    { text: "Day 3: Return home – morning flight." }
                ]
            },
            {
                type: "title-3",
                text: "Recovery from blepharoplasty surgery:"
            },
            {
                type: "unordered-list",
                text: "<strong>Do:</strong>",
                items: [
                    {
                        text: "Use ice packs on your eyes for 10 minutes every couple of hours on the night after surgery. The next day, use ice packs 4 to 5 times throughout the day."
                    },
                    { text: "Use prescribed eye drops or ointments as directed." },
                    { text: "Sleep with your head elevated above your chest for a few days." },
                    { text: "Apply cool compresses to reduce swelling." },
                    { text: "Wear dark sunglasses to protect your eyelids from the sun and wind." },
                    { text: "If needed, use acetaminophen to manage pain." }
                ]
            },
            {
                type: "unordered-list",
                text: "<strong>Don't:</strong>",
                items: [
                    { text: "Avoid strenuous activities for a week, including heavy lifting, swimming, jogging, or aerobics." },
                    { text: "Do not smoke." },
                    { text: "Do not rub your eyes." },
                    { text: "Avoid wearing contact lenses for about two weeks." },
                    { text: "Do not take aspirin, ibuprofen or any other drugs or herbal supplements that can increase bleeding." }
                ]
            },
            {
                type: "floating-left-image",
                imageAltText: "", // todo
                imageName: "" // todo
            }
        ],
        frequentlyAskedQuestions: {
            title: "Blepharoplasty FAQ’s",
            subTitle: "Frequently-Asked-Question about Blepharoplasty",
            text: "Get all your questions answered with our comprehensive FAQ section on Blepharoplasty Surgery. Learn about the procedure, its benefits, potential risks, and more. If you're curious about our packages or how cosmetic procedures could transform your life, reach out to us today using the inquiry form at the bottom of the screen. We're here to help you every step of the way!",
            questions: [
                {
                    question: "What is blepharoplasty?",
                    answer: "Blepharoplasty, also known as eyelid lift surgery, is a procedure that rejuvenates the upper or lower eyelids by removing excess skin and fat."
                },
                {
                    question: "Am I a good candidate for blepharoplasty?",
                    answer: "Good candidates typically have drooping or sagging eyelids that affect their appearance or vision. They should be in good health and have realistic expectations."
                },
                {
                    question: "How long does the procedure take?",
                    answer: "Blepharoplasty typically takes 1 to 2 hours, depending on whether you're having the upper lids, lower lids, or both treated."
                },
                {
                    question: "What type of anesthesia is used?",
                    answer: "The surgery is usually performed under local anesthesia with sedation, although general anesthesia may be used in some cases or patients request (there may be additional charge for general anesthesia)."
                },
                {
                    question: "What is recovery like after the surgery?",
                    answer: "Recovery usually takes about a week. Expect some swelling and bruising. Most patients return to work and normal activities within a few days."
                },
                {
                    question: "Will there be visible scars?",
                    answer: "Incisions are made along the natural creases of the eyelids, so scars are typically minimal and fade over time."
                },
                {
                    question: "How long do the results last?",
                    answer: "The results can last for many years. Ageing will continue, but the improvements from the surgery will remain for a long time."
                },
                {
                    question: "Are there any risks or complications?",
                    answer: "As with any surgery, there are risks such as infection, bleeding, scarring, and asymmetry. Your surgeon will discuss these with you during pre-surgery consultation."
                },
                {
                    question: "How do I care for my eyes after surgery?",
                    answer: "Follow your surgeon's instructions for post-surgery care, including keeping your eyes clean and applying any prescribed medicine."
                },
                {
                    question: "When can I wear makeup or contact lenses again?",
                    answer: "You should typically wait a week or two after surgery."
                },
                {
                    question: "When can I return to exercise or physical activity?",
                    answer: "Avoid strenuous activity for about 3 to 6 weeks to allow for proper healing."
                },

                {
                    question: "Do You Provide Transfers and Accommodation?",
                    answer: `<div>
                        <p>Yes, we offer a convenient solution through our "All-inclusive" package designed specifically for our patients. This package, available for an additional charge, includes:</p>
                        <ul>
                            <li><b>Accommodation with Breakfast:</b> You'll stay at a hotel just 5 minutes from the clinic.</li>
                            <li><b>Transfers Included:</b> All transfers to and from the airport, as well as between the hotel and the clinic, are covered.</li>
                        </ul>
                        <p>Additionally, you'll have a VIP Host with you from the moment you arrive until you leave. Your host will make sure you have everything you need to feel comfortable and will even take care of your prescriptions.</p>
                        <p>The cost of the package varies depending on how long you stay. If you plan to bring a companion, please let us know. We'll be happy to help arrange everything for both of your stays.</p>
                        <p>We want you to feel relaxed and comfortable during your stay. At Allmedica in Poland, we offer top-quality service and a pleasant experience from start to finish. Our team of highly skilled experts will take care of you throughout your journey. Choosing Allmedica for your cosmetic surgery is the right decision for a safe and satisfying experience. Let us know how we can assist you!</p>
                    </div>`
                },

                {
                    question: "Why Choose Allmedica for My Blepharoplasty Surgery in Poland?",
                    answer: `<ul>
                        <li><b>Years of Established Excellence:</b> With over 170,000 patients served since 2012 across 15 branches, Allmedica boasts extensive experience and expertise in general practice.</li>
                        <li><b>Adherence to Stringent Standards:</b> As a member of the European Union, Allmedica adheres to a strict sanitary regime, followed in Europe. Your safety and well-being are our top priorities, and we maintain the highest standards of cleanliness and hygiene.</li>
                        <li><b>Continuous Professional Development:</b> Our specialists continually enhance their skills and knowledge through participation in numerous workshops and seminars led by renowned innovators in their respective fields. This dedication to ongoing learning ensures that we stay at the forefront of medical advancements, delivering the latest and most effective treatments to our patients.</li>
                        <li><b>Positive Patient Feedback:</b> Our commitment and professionalism are reflected in the positive feedback we receive from our patients. At Allmedica, we take pride in our ability to approach each patient individually, providing personalized care and attention to address your unique needs and concerns.</li>
                        <li><b>Comprehensive Care for Your Well-being:</b> With Allmedica, you can expect comprehensive care that prioritizes your health and well-being. From your initial consultation to post-operative support, our dedicated team is here to guide you through every step of your surgery journey, ensuring a smooth and successful experience.</li>
                    </ul>`
                }
            ]
        }
    },
    {
        id: 10,
        name: "Breast Augmentation",
        title: "Breast Surgery (Breast Enlargement)",
        description:
            "Breast surgery encompasses a variety of surgical procedures designed to enhance, reconstruct, or alter the shape and appearance of the breasts. These surgeries can serve cosmetic purposes, such as improving aesthetics and body proportion, or medical purposes, such as treating medical conditions or reconstructing the breasts after surgery or injury.",
        pricePositionIds: [38, 78, 79, 80, 81, 82, 83],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28, 29],
        content: [
            {
                type: "unordered-list",
                text: "Here are some common types of breast surgery:",
                items: [
                    {
                        text: "<b>Breast Augmentation:</b> This procedure, also known as augmentation mammoplasty, involves using implants or fat transfer to increase the size or fullness of the breasts."
                    },
                    {
                        text: "<b>Breast Reduction:</b> Also known as reduction mammoplasty, this surgery removes excess breast tissue, fat, and skin to reduce the size and weight of the breasts. It can alleviate discomfort and improve mobility for those with overly large breasts."
                    },
                    {
                        text: "<b>Breast Lift:</b> Known as mastopexy, this procedure lifts and reshapes sagging breasts to create a more youthful and firm appearance. It may also involve repositioning the nipples."
                    },
                    {
                        text: "<b>Breast Reconstruction:</b> This surgery is often performed after a mastectomy (removal of the breast due to cancer) to rebuild the breast to a more natural shape and size. Reconstruction can be done using implants, tissue from other parts of the body, or a combination of both."
                    },
                    {
                        text: "<b>Breast Asymmetry Correction:</b> This surgery aims to correct noticeable differences in size or shape between the breasts to create a more balanced appearance."
                    }
                ]
            },
            {
                type: "paragraph",
                text: "Breast surgery is a personal decision. Recovery times and experiences can vary depending on the type of procedure, so it's important to follow the surgeon's post-operative instructions for the best results."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            subTitle: "",
            text: "",
            questions: []
        }
    },
    {
        id: 11,
        name: "Buccal Fat Removal",
        title: "Buccal Fat Removal", // todo
        description: "", // todo
        pricePositionIds: [55],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        // todo
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            subTitle: "",
            text: "",
            questions: []
        }
    },
    {
        id: 12,
        name: "Facelift",
        title: "Facelift Surgery (Face Lifting) in Allmedica, Poland",
        description:
            "A facelift is a procedure that helps remove extra skin that starts to wrinkle or sag due to aging or sun exposure. It's like a magic touch that can make you look younger and feel more confident, all without altering your natural facial shape or features. As we grow older, gravity starts playing its part, leading to drooping in areas like the eyebrows, cheekbones, and neck. A facelift can work wonders by smoothing out cheek folds, tightening loose skin around the neck, and saying goodbye to jowls, giving you a fresh, natural, and youthful appearance.",
        pricePositionIds: [39, 40, 41, 42],
        specializationIds: [5],
        images: [{ id: useUniqueId(), src: "/img/services/chin_lipo_16_days_post_surgery_1.webp", alt: "Chin lipo 16 days post surgery" }],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            },
            {
                type: "unordered-list",
                text: "Here is a typical schedule for your Facelift Surgery:",
                items: [
                    {
                        text: "Day 1: Arrive late afternoon and spend the night in a hotel. Rest."
                    },
                    {
                        text: "Day 2: Attend pre-operation consultations and tests. Undergo the Facelift surgery. Spend the night in the clinic."
                    },
                    {
                        text: "Day 3: Get discharged from the clinic. Spend the night in the hotel."
                    },
                    {
                        text: "Day 4: Rest and recover. Spend the night in the hotel."
                    },
                    {
                        text: "Day 5: Rest and recover. Spend the night in the hotel."
                    },
                    {
                        text: "Day 6: Follow-up consultation. Return home."
                    }
                ]
            }
        ],
        frequentlyAskedQuestions: {
            title: "Facelift Surgery FAQs",
            subTitle: "Frequently-Asked-Question about Facelift",
            text: "Get all your questions answered with our comprehensive FAQ section on Facelift Surgery. Learn about the procedure, its benefits, potential risks, and more. If you're curious about our packages or how cosmetic procedures could transform your life, reach out to us today using the inquiry form at the bottom of the screen. We're here to help you every step of the way!",
            questions: [
                {
                    question: "Who Qualifies for Facelift Surgery at Allmedica?",
                    answer: `<div>
                        <p>To undergo our facelift surgery, patients must meet specific criteria outlined by our medical team. Generally, candidates for any of our cosmetic surgeries at Allmedica in Poland must meet the following:</p>
                        <ul>
                            <li>Good health</li>
                            <li>Absence of high-risk conditions such as diabetes, high blood pressure, bleeding disorders, heart diseases, or diagnosed depression.</li>
                        </ul>
                        <small>*Please note: Individuals diagnosed with obesity or those who regularly smoke and consume alcohol are not eligible for cosmetic surgeries.</small>
                    </div>`
                },
                {
                    question: "What to Expect After Facelift Surgery at Allmedica?",
                    answer: `<div>
                        <p>Facelift surgery doesn’t halt the ageing process, but it can provide temporary relief from its effects, often making individuals appear up to a decade younger. It can also mitigate the impact
                        of hereditary factors, sun exposure, pollution, and smoking. These factors contribute to the enduring popularity of facelift surgery. Following a facelift surgery, patients should anticipate:</p>
                        <ul>
                        <li>Minimal discomfort.</li>
                        <li>Surgical drains, if used, may be removed within 4 days.</li>
                        <li>Swelling, discoloration, and bruising may persist for up to 10 days.</li>
                        <li>Sensations of tightness and numbness may be experienced for approximately 10 days.</li>
                        <li>A compression garment may need to be worn for up to 3 weeks.</li>
                        <li>Avoidance of strenuous activity and exercise for 6 weeks.</li>
                        <li>Noticeable rejuvenation and a more youthful appearance typically become apparent after about 10 days.</li>
                        </ul>
                        <p>
                        Initially, facelift surgery scars may appear pinkish for the first few months but tend to become less noticeable over time. Additionally, as these scars are primarily located near the hairline
                        and within natural skin creases, they are often discreetly positioned to begin with.
                        </p>
                        <small>*While a facelift can significantly rejuvenate one's appearance and its effects may last for several years, the natural aging process persists. The outcomes of facelift surgery vary from
                        patient to patient. Facelifts do not improve skin texture or quality. While some patients may only undergo one facelift, others may opt for a second procedure seven to fifteen years
                        later.</small>
                    </div>`
                },
                {
                    question: "Benefits and Risks of Facelift Surgery at Allmedica.",
                    answer: `<div>
                        <p>Facelift Benefits at Allmedica:</p>
                        <ul>
                            <li>Tightened skin</li>
                            <li>Reduced sagging skin</li>
                            <li>Long-lasting results</li>
                            <li>Youthful appearance</li>
                            <li>Enhanced self-confidence</li>
                        </ul>
                    </div>`
                },
                {
                    question: "Potential Complications of Facelift Surgery at Allmedica:",
                    answer: `<div>
                        <p>It's important to acknowledge that while facelifts offer numerous benefits, they are also surgical procedures, and as such, carry inherent risks and potential complications, including:</p>
                        <ul>
                            <li>Bleeding</li>
                            <li>Bruising and hematoma (accumulation of blood under the skin)</li>
                            <li>Temporary skin discoloration and tenderness</li>
                            <li>Risk of infection</li>
                            <li>Scarring if post-operative instructions are not followed diligently</li>
                            <li>Longer recovery period for patients who smoke</li>
                        </ul>
                        <small>*Facelifts deliver immediate and noticeable rejuvenation, but they're not permanent solutions. The duration of results varies based on factors such as the patient’s bone structure and skin quality. Typically, patients may consider undergoing another facelift procedure every seven to ten years if desired.</small>
                    </div>`
                },
                {
                    question: "What’s Included in the Allmedica Facelift Surgery?",
                    answer: `<div>
                        <p>At Allmedica, we ensure a seamless and comfortable experience for our facelift patients. Our commitment to excellence in medical care and patient experience is unwavering. With state-of-the-art facilities equipped with advanced operating rooms, we collaborate with leading medical professionals in Europe.</p>
                        <p>Included in the Facelift Surgery:</p>
                        <ul>
                            <li>All pre-surgery testing and examinations</li>
                            <li>All associated surgery procedure expenses, including surgeon, anaesthesia, equipment, medicines, and nursing care</li>
                            <li>A dedicated Case Management team to assist you throughout your pre- and post-facelift journey</li>
                            <li>1-2 nights at our clinic (incl. all personnel, medicines, etc.)</li>
                            <li>Coordination for pre-facelift consultations, if required</li>
                        </ul>
                    </div>`
                },
                {
                    question: "Do You Provide Transfers and Accommodation?",
                    answer: `<div>
                        <p>Yes, we offer a convenient solution through our "All-inclusive" package designed specifically for our patients. This package, available for an additional charge, includes:</p>
                        <ul>
                            <li><b>Accommodation with Breakfast:</b> You'll stay at a hotel just 5 minutes from the clinic.</li>
                            <li><b>Transfers Included:</b> All transfers to and from the airport, as well as between the hotel and the clinic, are covered.</li>
                        </ul>
                        <p>Additionally, you'll have a VIP Host with you from the moment you arrive until you leave. Your host will make sure you have everything you need to feel comfortable and will even take care of your prescriptions.</p>
                        <p>The cost of the package varies depending on how long you stay. If you plan to bring a companion, please let us know. We'll be happy to help arrange everything for both of your stays.</p>
                        <p>We want you to feel relaxed and comfortable during your stay. At Allmedica in Poland, we offer top-quality service and a pleasant experience from start to finish. Our team of highly skilled experts will take care of you throughout your journey. Choosing Allmedica for your cosmetic surgery is the right decision for a safe and satisfying experience. Let us know how we can assist you!</p>
                    </div>`
                },
                {
                    question: "Why Choose Allmedica for My Facelift Surgery in Poland?",
                    answer: `<ul>
                        <li><b>Years of Established Excellence:</b> With over 170,000 patients served since 2012 across 15 branches, Allmedica boasts extensive experience and expertise in general practice.</li>
                        <li><b>Adherence to Stringent Standards:</b> As a member of the European Union, Allmedica adheres to a strict sanitary regime, ensuring that our standards match those of renowned clinics in Europe. Your safety and well-being are our top priorities, and we maintain the highest standards of cleanliness and hygiene.</li>
                        <li><b>Continuous Professional Development:</b> Our specialists continually enhance their skills and knowledge through participation in numerous workshops and seminars led by renowned innovators in their respective fields. This dedication to ongoing learning ensures that we stay at the forefront of medical advancements, delivering the latest and most effective treatments to our patients.</li>
                        <li><b>Positive Patient Feedback:</b> Our commitment and professionalism are reflected in the positive feedback we receive from our patients. At Allmedica, we take pride in our ability to approach each patient individually, providing personalized care and attention to address your unique needs and concerns.</li>
                        <li><b>Comprehensive Care for Your Well-being:</b> With Allmedica, you can expect comprehensive care that prioritizes your health and well-being. From your initial consultation to post-operative support, our dedicated team is here to guide you through every step of your surgery journey, ensuring a smooth and successful experience.</li>
                    </ul>`
                },
                {
                    question: "What to Expect During Facelift Surgery at Allmedica?",
                    answer: `<div>
                        <p>During a facelift procedure at Allmedica, the process typically takes around five to six hours and involves either local or intravenous anesthesia for your comfort. The incisions are
                        strategically placed, starting at the temples above the hairline, continuing around the ear, and sometimes extending to the base of the scalp if necessary. Additionally, if the neck area
                        requires attention, another incision may be made under the chin.</p>
                        <p>Afterwards, the skin is carefully separated from the underlying fat and muscle. Trimming or suction may be used to remove excess fat, enhancing the contours of the neck and chin. Muscles are
                        then tightened, and the skin is gently repositioned before any surplus skin is removed. Stitches are utilized to secure the tissue layers and close the incisions, with the possibility of using
                        metal clips for added support if required.</p>
                        <p>Following the procedure, drainage tubes may be placed beneath the skin behind the ear to eliminate any collected blood. Loose bandages are applied around the head to help manage bruising and
                        swelling.</p>
                    </div>`
                },
                {
                    question: "What Types of Facelifts Are Available at Allmedica?  ",
                    answer: `<div>
                        <p>At Allmedica, we provide the following types of facelift surgery: Face Lift Surgery, Face and Neck Lift, Face Lift with Upper and Lower Eyelids. All procedures are tailored to meet each patient's specific needs.</p>
                        <p>Face Lift Surgery: This comprehensive procedure targets various areas including the brows, eyes, neck, and may involve liposuction and cementoplasty (muscle tightening under the chin to address jowls). Incisions are typically made in front of the ear and may extend around the back of the ear if necessary.</p>
                        <p>Mini Facelift Surgery: This option focuses on either the lower or upper face, including the temporal region. Additionally, it can be performed with or without addressing the eye area.</p>
                        <p>While a facelift can significantly enhance facial appearance by reducing wrinkles, it may not completely eliminate them. Therefore, additional facial treatments such as chemical and laser resurfacing, Botox, and collagen injections may be recommended in conjunction with a facelift to address specific concerns like laugh lines, wrinkles, age spots, and acne scars.</p>
                    </div>`
                }
            ]
        }
    },
    {
        id: 13,
        name: "Liposuction – Vaser Liposuction",
        title: "Vaser Liposuction Surgery in Allmedica, Poland",
        description:
            "Liposuction, also known as lipoplasty or liposculpture, is a procedure that enables surgeons to target and remove stubborn fat from areas like the hips, thighs, buttocks, or abdomen. This process effectively eliminates bulges and unwanted contours, helping patients regain confidence and comfort in their bodies.",
        pricePositionIds: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [23],
        content: [
            {
                type: "paragraph",
                text: "In addition to its cosmetic benefits, liposuction or abdominoplasty can lead to a reduction in overall body fat percentage, offering significant health advantages such as a decreased risk of heart disease and type II diabetes."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            },
            {
                type: "title-2",
                text: "Recovery Period: What to Expect"
            },
            {
                type: "unordered-list",
                text: "After surgery, you may experience some soreness, bruising, and swelling for the first few days. Follow these tips to help you recover smoothly:",
                items: [
                    {
                        text: `<b>Rest:</b> Get plenty of rest to help your body heal.`
                    },
                    {
                        text: `<b>Walking:</b> Light walking can help prevent blood clots.`
                    },
                    {
                        text: `<b>Medication:</b> Take your prescribed pain medicine and antibiotics as directed.`
                    },
                    {
                        text: `<b>Avoid Water:</b> Stay out of bathtubs and Jacuzzis for a while.`
                    },
                    {
                        text: `<b>Stay Hydrated:</b> Drink plenty of water to help your body heal.`
                    }
                ]
            },
            {
                type: "paragraph",
                text: "You'll also need to wear a special compression garment for several weeks to help your body shape correctly. Most patients can resume some daily activities within the first week and all other physical activities by the end of six weeks."
            },
            {
                type: "title-2",
                text: "Liposuction Results: What to Expect"
            },
            {
                type: "paragraph",
                text: "After surgery, you'll notice changes right away, but it takes time for your body to fully heal. As the swelling goes down, your final results will gradually appear. Within four to five months, you'll see the full impact of the procedure."
            },
            {
                type: "paragraph",
                text: "During liposuction, fat cells are completely removed, so the results can be long-lasting. Your plastic surgeon will help you set realistic expectations based on your health and lifestyle."
            },
            {
                type: "title-2",
                text: "How to Maintain Your Figure"
            },
            {
                type: "unordered-list",
                text: "To keep your new shape, stick to a healthy lifestyle:",
                items: [
                    {
                        text: `<b>Eat Healthy:</b> Choose nutritious foods and keep an eye on your calorie intake.`
                    },
                    {
                        text: `<b>Exercise:</b> Regular physical activity helps keep your weight steady.`
                    },
                    {
                        text: `<b>Stay Consistent:</b> Avoid big changes in your weight, which can affect your results.`
                    }
                ]
            },
            {
                type: "paragraph",
                text: "By following these steps, you can enjoy your new body for a long time. Let your doctor know if you have any questions or concerns."
            },
            {
                type: "paragraph",
                text: "Based on your goals, our team can help you determine whether liposuction is the best solution for your needs. Our foreign patients will be asked to send specific photographs to determine which procedure best responds to their needs."
            },
            {
                type: "unordered-list",
                text: "Here is a typical schedule for your Liposuction under a local anaesthesia procedure – less than 48 hours:",
                items: [
                    {
                        text: "Day 1: Arrive late afternoon and spend the night in a hotel."
                    },
                    {
                        text: "Day 2: Attend pre-operation consultations and tests. Undergo the gastric balloon procedure. Get discharged from the clinic. Spend the night in the hotel."
                    },
                    {
                        text: "Day 3: Return home – morning flight."
                    }
                ]
            },
            {
                type: "unordered-list",
                text: "Here is a typical schedule for your Liposuction under a general anaesthesia procedure – less than 72 hours:",
                items: [
                    {
                        text: "Day 1: Arrive late afternoon and spend the night in a hotel."
                    },
                    {
                        text: "Day 2: Attend pre-operation consultations and tests. Undergo the liposuction surgery. Spend the night in the clinic."
                    },
                    {
                        text: "Day 3: Get discharged from the clinic. Spend the night in the hotel."
                    },
                    {
                        text: "Day 4: Return home."
                    }
                ]
            },
            {
                // todo
                type: "block-image",
                imageAltText: "", // todo
                imageName: ""
            }
        ],
        frequentlyAskedQuestions: {
            title: "Frequently-Asked-Question about Liposuction",
            text: "Get all your questions answered with our comprehensive FAQ section on Liposuction Surgery. Learn about the procedure, its benefits, potential risks, and more. If you're curious about our packages or how cosmetic procedures could transform your life, reach out to us today using the inquiry form at the bottom of the screen. We're here to help you every step of the way!",
            questions: [
                {
                    question: "Who Qualifies for Liposuction Surgery at Allmedica?",
                    answer: `<div>
                        <p>To undergo liposuction surgery, patients must meet specific criteria outlined by our medical team. Generally, candidates for any of our cosmetic surgeries at Allmedica in Poland must meet the following:</p>
                        <ul>
                            <li>Good health</li>
                            <li>Absence of high-risk conditions such as diabetes, high blood pressure, bleeding disorders, heart diseases, or diagnosed depression.</li>
                        </ul>
                        <small>*Please note: Individuals diagnosed with obesity or those who regularly smoke and consume alcohol are not eligible for cosmetic surgeries.</small>
                    </div>`
                },
                {
                    question: "Which Areas of the Body Can Benefit from Liposuction?",
                    answer: "Liposuction is a procedure that can target various areas of the body, including the tummy, upper back, lower back, love handles, buttocks, thighs, face, neck, chin, upper arms, knees, calves, and ankles. By addressing problem areas liposuction helps patients achieve smoother, slimmer, and firmer contours."
                },
                {
                    question: "What’s Included in Your Liposuction Surgery?",
                    answer: `<div>
                        <p>Our comprehensive liposuction surgery covers everything you need for a smooth and successful procedure.</p>
                        <p>This includes:</p>
                        <ul>
                            <li>A dedicated Case Manager to guide you through the pre and post liposuction experience.</li>
                            <li>Pre-liposuction consultation if needed</li>
                            <li>Medical tests conducted before the surgery to ensure your safety and suitability for the procedure.</li>
                            <li>The surgery itself, which encompasses all aspects such as personnel, medications, and any necessary equipment.</li>
                            <li>1 night of accommodation at our clinic, where you'll receive round-the-clock medical care, including medications and meals if </li>
                        </ul>
                        <p>At our facility, we maintain the highest standards of medical care and patient comfort. Our dedication to excellence is reflected in our use of cutting-edge technology and services, including state-of-the-art operating rooms and comprehensive internal diagnostic services across all medical specialties. Your well-being is our top priority, and we are committed to providing you with the best possible experience throughout your tummy tuck surgery journey.</p>
                    </div>`
                },
                {
                    question: "Do You Provide Transfers and Accommodation?",
                    answer: `<div>
                        <p>Yes, we offer a convenient solution through our "All-inclusive" package designed specifically for our patients. This package, available for an additional charge, includes:</p>
                        <ul>
                            <li><b>Accommodation with Breakfast:</b> You'll stay at a hotel just 5 minutes from the clinic.</li>
                            <li><b>Transfers Included:</b> All transfers to and from the airport, as well as between the hotel and the clinic, are covered.</li>
                        </ul>
                        <p>The cost of the package varies depending on how long you stay. If you plan to bring a companion, please let us know. We'll be happy to help arrange everything for both of your stays.</p>
                        <p>We want you to feel relaxed and comfortable during your stay. At Allmedica in Poland, we offer top-quality service and a pleasant experience from start to finish. Our team of highly skilled experts will take care of you throughout your journey. Choosing Allmedica for your cosmetic surgery is the right decision for a safe and satisfying experience. Let us know how we can assist you!</p>
                    </div>`
                },
                {
                    question: "Why Choose Allmedica for My Tummy Tuck Surgery in Poland?",
                    answer: `<ul>
                        <li><b>Years of Established Excellence:</b>  With over 170,000 patients served since 2012 across 15 branches, Allmedica boasts extensive experience and expertise in general practice.</li>
                        <li><b>Adherence to Stringent Standards:</b>  As a member of the European Union, Allmedica adheres to a strict sanitary regime, followed in Europe. Your safety and well-being are our top priorities, and we maintain the highest standards of cleanliness and hygiene.</li>
                        <li><b>Continuous Professional Development:</b>  Our specialists continually enhance their skills and knowledge through participation in numerous workshops and seminars led by renowned innovators in their respective fields. This dedication to ongoing learning ensures that we stay at the forefront of medical advancements, delivering the latest and most effective treatments to our patients.</li>
                        <li><b>Positive Patient Feedback:</b>  Our commitment and professionalism are reflected in the positive feedback we receive from our patients. At Allmedica, we take pride in our ability to approach each patient individually, providing personalized care and attention to address your unique needs and concerns.</li>
                        <li><b>Comprehensive Care for Your Well-being:</b>  With Allmedica, you can expect comprehensive care that prioritizes your health and well-being. From your initial consultation to post-operative support, our dedicated team is here to guide you through every step of your surgery journey, ensuring a smooth and successful experience.    </li>
                    </ul>`
                }
            ]
        }
    },
    {
        id: 14,
        name: "Mommy Makeover",
        title: "Mommy makeover in Allmedica, Poland",
        description:
            "A mommy makeover surgery is a combination of cosmetic procedures designed to help women restore their pre-pregnancy bodies or achieve their desired body image after childbirth. The specific combination of procedures varies based on the individual's needs and goals, but typically includes surgeries such as:",
        pricePositionIds: [36, 37],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [24],
        content: [
            {
                type: "unordered-list",
                items: [
                    {
                        text: `<b>Breast enhancement:</b> This may include breast augmentation, breast lift, or breast reduction, depending on the patient's goals.`
                    },
                    {
                        text: `<b>Tummy tuck (abdominoplasty):</b> This procedure tightens the abdominal muscles and removes excess skin and fat from the midsection.`
                    },
                    {
                        text: `<b>Liposuction:</b> Liposuction may be performed on various areas such as the thighs, hips, or abdomen to remove stubborn pockets of fat and improve body contour.`
                    }
                ]
            },
            {
                type: "paragraph",
                text: "These surgeries are often performed together in one session, but the combination can be customized to each patient's preferences and needsIt's important to know your goals and understand the risks and benefits. A personalized treatment plan is created for every patient. Recovery times vary depending on the procedures involved, but your surgeon will provide guidance on what to expect and how to care for yourself post-surgery."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            },
            {
                type: "unordered-list",
                text: "Here is a typical schedule for your Mommy Makeover Surgery:",
                items: [
                    {
                        text: "Day 1: Arrive late afternoon and spend the night in a hotel. Rest."
                    },
                    {
                        text: "Day 2: Attend pre-operation consultations and tests. Undergo the Mommy Makeover Surgery. Spend the night in the clinic."
                    },
                    {
                        text: "Day 3: Get discharged from the clinic. Spend the night in the hotel."
                    },
                    {
                        text: "Day 4: Rest and recover. Spend the night in the hotel."
                    },
                    {
                        text: "Day 5: Follow-up consultation. Return home."
                    }
                ]
            },
            {
                type: "note",
                text: "Please note: The information provided on this website is for informational purposes only. Patients should understand that individual results may vary and should not expect identical outcomes to those depicted on this website. Each patient's experience is unique and influenced by factors such as their specific condition, lifestyle, and post-surgery care. It's important to consult with a healthcare professional to understand the potential outcomes and considerations specific to your situation."
            },
            {
                // todo image
                type: "block-image",
                imageAltText: "",
                imageName: ""
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            subTitle: "",
            text: "",
            questions: []
        }
    },
    {
        id: 15,
        name: "Otoplasty",
        title: "Otoplasty Surgery (Ear Surgery)",
        description:
            "Otoplasty, also known as ear surgery, is a procedure that reshapes or repositions the ears to improve their appearance and proportion. This surgery can help if you feel your ears stick out too much or if they have an unusual shape.",
        pricePositionIds: [51],
        specializationIds: [5],
        images: [
            { id: useUniqueId(), src: "/img/services/otoplasty_2_1.webp", fit: "contain", alt: "Otoplasty surgery" },
            { id: useUniqueId(), src: "/img/services/otoplasty_2_2.webp", fit: "contain", alt: "Otoplasty surgery" },
            { id: useUniqueId(), src: "/img/services/otoplasty_3.webp", fit: "contain", alt: "Otoplasty surgery" },
            { id: useUniqueId(), src: "/img/services/otoplasty_4.webp", fit: "contain", alt: "Otoplasty surgery" },
            { id: useUniqueId(), src: "/img/services/otoplasty_before_after.webp", fit: "contain", alt: "Otoplasty before after" },
            { id: useUniqueId(), src: "/img/services/otoplasty.webp", fit: "contain", alt: "Otoplasty" },
            { id: useUniqueId(), src: "/img/services/otoplasty_1.webp", fit: "contain", alt: "Otoplasty surgery" }
        ],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            },
            {
                type: "unordered-list",
                text: "What Can Otoplasty Do:",
                items: [
                    {
                        text: "Reshape Ears: Adjust the shape of your ears for a more natural look."
                    },
                    {
                        text: "Reposition Ears: Move the ears closer to the head if they stick out too far."
                    },
                    {
                        text: "Improve Symmetry: Make your ears look more even and balanced."
                    }
                ]
            },
            {
                type: "unordered-list",
                text: "What Happens During Otoplasty:",
                items: [
                    {
                        text: "Consultation: You'll meet with a surgeon to discuss your goals and learn what the surgery involves."
                    },
                    {
                        text: "Anesthesia: The procedure most of the time is done under local anesthesia (numbing the area)."
                    },
                    {
                        text: "Surgery: The surgeon will make small incisions behind your ears to reshape or reposition them. The incisions are usually hidden, so scars are minimal."
                    }
                ]
            },
            {
                type: "unordered-list",
                text: "Recovery:",
                items: [
                    {
                        text: "Initial Healing: You'll experience some swelling and bruising around your ears for a few days. You will need to wear a headband to protect your ears."
                    },
                    {
                        text: "Return to Activities: Most people can return to work and regular activities within a week. Avoid strenuous activities for a few weeks."
                    }
                ]
            },
            {
                type: "unordered-list",
                text: "Benefits of Otoplasty:",
                items: [
                    {
                        text: "Boost Confidence: You may feel more confident with your ears reshaped."
                    },
                    {
                        text: "Permanent Results: The changes from otoplasty are long-lasting."
                    },
                    {
                        text: "Minimal Scarring: Incisions are hidden behind the ears, so scars are not easily visible."
                    }
                ]
            },
            {
                type: "paragraph",
                text: "Otoplasty can give you the ear shape you want and improve how you feel about your appearance."
            },
            {
                type: "unordered-list",
                text: "Here is a typical schedule for your Otoplasty Surgery – less than 48 hours:",
                items: [
                    {
                        text: "Day 1: Arrive late afternoon and spend the night in a hotel."
                    },
                    {
                        text: "Day 2: Attend pre-operation consultations and tests. Undergo the gastric balloon procedure. Get discharged from the clinic. Spend the night in the hotel."
                    },
                    {
                        text: "Day 3: Return home – morning flight."
                    }
                ]
            },
            {
                // todo image
                type: "block-image",
                imageAltText: "",
                imageName: ""
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            subTitle: "",
            text: "",
            questions: []
        }
    },
    {
        id: 16,
        name: "Rhinoplasty",
        title: "Rhinoplasty Surgery (Nose Job Surgery)",
        description:
            'Rhinoplasty, often called a "nose job," is a surgical procedure that involves reshaping or modifying the size and structure of the nose. It is a versatile surgery that can address a range of concerns and can be performed for cosmetic reasons to improve the appearance of the nose, or for medical reasons to correct breathing problems or deformities caused by injury.',
        pricePositionIds: [52, 53],
        specializationIds: [5],
        images: [{ id: useUniqueId(), src: "/img/services/rhinoplasty_1.webp", alt: "Rhinoplasty surgery" }],
        staffIds: [1, 10],
        reviewIds: [28],
        content: [
            {
                type: "unordered-list",
                items: [
                    {
                        text: `<b>Cosmetic reasons:</b> Rhinoplasty can adjust the size, shape, or angle of the nose to create a more harmonious balance with other facial features. It can address concerns such as a prominent hump on the bridge of the nose, a wide nasal tip, asymmetry, or an overly large or small nose.`
                    },
                    {
                        text: `<b>Medical reasons:</b> Rhinoplasty can be used to correct functional issues such as a deviated septum, which can obstruct airflow and cause breathing difficulties. It can also repair damage or deformities caused by trauma or injury, improving both appearance and function.`
                    }
                ]
            },
            {
                type: "paragraph",
                text: "By tailoring the procedure to each patient's specific needs, rhinoplasty can enhance self-confidence and quality of life while providing relief from medical issues related to nasal structure."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            },
            {
                type: "unordered-list",
                text: "Here is a typical schedule for your Rhinoplasty Surgery - in as little as 5 days:",
                items: [
                    {
                        text: "Day 1: Arrive late afternoon and spend the night in a hotel. Rest."
                    },
                    {
                        text: "Day 2: Attend pre-operation consultations and tests. Undergo the rhinoplasty surgery. Spend the night in the clinic. "
                    },
                    {
                        text: "Day 3: Get discharged from the clinic. Spend the night in the hotel."
                    },
                    {
                        text: "Day 4: Rest and recover. Spend the night in the hotel."
                    },
                    {
                        text: "Day 5: Follow-up consultation. Return home."
                    }
                ]
            },
            {
                // todo image
                type: "block-image",
                imageAltText: "",
                imageName: ""
            }
        ],
        frequentlyAskedQuestions: {
            title: "Rhinoplasty Surgery Surgery FAQ’s",
            subTitle: "Frequently-Asked-Question about Rhinoplasty Surgery",
            text: "",
            questions: [
                {
                    question: "What are the reasons people get rhinoplasty?",
                    answer: "People undergo rhinoplasty for various reasons, including cosmetic improvements such as reshaping the nose for better facial harmony or to correct nasal deformities. It can also be done for functional reasons, such as improving breathing issues caused by a deviated septum or other structural problems."
                },
                {
                    question: "What happens during a rhinoplasty consultation?",
                    answer: "During a rhinoplasty pre-surgery consultation, you'll meet with a surgeon to discuss your goals and expectations for the procedure. The surgeon will examine your nose, take photos, and evaluate your medical history to determine the best surgical approach. You'll also discuss potential risks and outcomes."
                },
                {
                    question: "How is rhinoplasty performed?",
                    answer: "Rhinoplasty is typically performed under general anaesthesia. The surgeon makes incisions inside the nostrils (closed rhinoplasty) or on the columella (open rhinoplasty). The surgeon then reshapes the bone and cartilage of the nose to achieve the desired look and function. The incisions are closed, and a splint may be placed on the nose for support during recovery."
                },
                {
                    question: "What can I expect during recovery?",
                    answer: "After surgery, you can expect swelling and bruising around the nose and eyes. Pain and discomfort can be managed with prescribed medication. The splint will be removed within a week, and you may need to keep your head elevated for a few days. Most people can return to work and light activities within a week, but full recovery may take several weeks."
                },
                {
                    question: "When will I see the final results?",
                    answer: "The final results of rhinoplasty may take several months to become fully visible as swelling gradually subsides. It's important to be patient during the healing process and follow your surgeon's instructions for the best outcome."
                },
                {
                    question: "Are there risks associated with rhinoplasty?",
                    answer: "Like any surgery, rhinoplasty carries some risks, including infection, bleeding, scarring, and anaesthesia complications. Other risks include asymmetry, difficulty breathing, or dissatisfaction with the appearance. "
                },
                {
                    question: "Will I have visible scars after rhinoplasty?",
                    answer: "In closed rhinoplasty, all incisions are made inside the nose, so there are no visible scars. In open rhinoplasty, a small incision is made on the columella, which may leave a tiny, discreet scar that fades over time."
                },
                {
                    question: "What should I avoid before and after rhinoplasty?",
                    answer: "Before surgery, avoid smoking, certain medications, and supplements that can increase bleeding risk. After surgery, avoid strenuous activities, nose-blowing, and wearing glasses that rest on the nose for a few weeks. Follow your surgeon's instructions for a smooth recovery."
                },
                {
                    question: "How to preper for Surgery Under General Anaesthesia",
                    answer: `<p>To ensure a smooth surgery and recovery, please follow these pre-op instructions:</p>
                            <ul>
                                <li><b>Fasting:</b> Do not eat or drink anything for 8 hours before the operation. This is crucial for your safety during anaesthesia.</li>
                                <li><b>Comfortable Clothing:</b> Wear comfortable, loose-fitting clothing to the surgery. This will make it easier to change and more comfortable during recovery.</li>
                                <li><b>Shower:</b> Take a shower on the morning of the surgery to help you relax and prepare.</li>
                                <li><b>No Makeup, Pedicure, or Manicure:</b> Please do not wear makeup and avoid having a manicure or pedicure on the day of surgery.</li>
                                <li><b>Remove Metal Objects:</b> Take off any metal objects such as piercings, jewellery, and hairpins before the operation.</li>
                                <li><b>Herbal and Supportive Products:</b> Avoid herbal teas, green tea, and other supportive products for at least 10 days before and after surgery. Green tea can increase the risk of bleeding.</li>
                                <li><b>Health Concerns:</b> Let us know if you have any health issues like a cold or flu. This could affect your surgery and may lead to a cancellation.</li>
                                <li><b>Medications:</b> Inform us if you take blood pressure, heart, diabetes, or thyroid medications. Bring your medication with you on the day of surgery. Also, avoid taking vitamin E.</li>
                                <li><b>Rest:</b> Get plenty of rest before surgery. Avoid any strenuous activities.</li>
                                <li><b>No Smoking or Alcohol:</b> Stop smoking and drinking alcohol one week before surgery.</li>
                            </ul>`
                },
                {
                    question: "Do You Provide Transfers and Accommodation",
                    answer: `<p>Yes, we offer a convenient solution through our "All-inclusive" package designed specifically for our patients. This package, available for an additional charge, includes:</p>
                            <ul>
                                <li><b>Accommodation with Breakfast:</b> You'll stay at a hotel just 5 minutes from the clinic.</li>
                                <li><b>Transfers Included:</b> All transfers to and from the airport, as well as between the hotel and the clinic, are covered.</li>
                            </ul>
                            <p>Additionally, you'll have a VIP Host with you from the moment you arrive until you leave. Your host will make sure you have everything you need to feel comfortable and will even take care of your prescriptions.</p>
                            <p>The cost of the package varies depending on how long you stay. If you plan to bring a companion, please let us know. We'll be happy to help arrange everything for both of your stays.</p>
                            <p>We want you to feel relaxed and comfortable during your stay. At Allmedica in Poland, we offer top-quality service and a pleasant experience from start to finish. Our team of highly skilled experts will take care of you throughout your journey. Choosing Allmedica for your cosmetic surgery is the right decision for a safe and satisfying experience. Let us know how we can assist you!</p>`
                },
                {
                    question: "Why Choose Allmedica for My Tummy Tuck Surgery in Poland?",
                    answer: `<ul>
                                <li><b>Years of Established Excellence:</b> With over 170,000 patients served since 2012 across 15 branches, Allmedica boasts extensive experience and expertise in general practice.</li>
                                <li><b>Adherence to Stringent Standards:</b> As a member of the European Union, Allmedica adheres to a strict sanitary regime, followed in Europe. Your safety and well-being are our top priorities, and we maintain the highest standards of cleanliness and hygiene.</li>
                                <li><b>Continuous Professional Development:</b> Our specialists continually enhance their skills and knowledge through participation in numerous workshops and seminars led by renowned innovators in their respective fields. This dedication to ongoing learning ensures that we stay at the forefront of medical advancements, delivering the latest and most effective treatments to our patients.</li>
                                <li><b>Positive Patient Feedback:</b> Our commitment and professionalism are reflected in the positive feedback we receive from our patients. At Allmedica, we take pride in our ability to approach each patient individually, providing personalized care and attention to address your unique needs and concerns.</li>
                                <li><b>Comprehensive Care for Your Well-being:</b> With Allmedica, you can expect comprehensive care that prioritizes your health and well-being. From your initial consultation to post-operative support, our dedicated team is here to guide you through every step of your surgery journey, ensuring a smooth and successful experience.</li>
                            </ul>`
                }
            ]
        }
    },
    {
        id: 17,
        name: "Vaginoplasty",
        title: "Vaginoplasty Surgery ", // todo
        description: "", // todo
        pricePositionIds: [65, 66, 67],
        specializationIds: [2],
        images: [],
        staffIds: [6],
        reviewIds: [], // todo
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 18,
        name: "Labiaplasty",
        title: "Labiaplasty Surgery ", // todo
        description: "", // todo
        pricePositionIds: [63, 64, 66],
        specializationIds: [2],
        images: [],
        staffIds: [6],
        reviewIds: [27],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 19,
        name: "Prolapsed uterus treatment",
        title: "Prolapsed uterus treatment Surgery ", // todo
        description: "", // todo
        pricePositionIds: [68],
        specializationIds: [2],
        images: [],
        staffIds: [6],
        reviewIds: [], // todo
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 20,
        name: "Gastric Bypass",
        title: "Gastric Bypass Surgery ", // todo
        description: "", // todo
        pricePositionIds: [75],
        specializationIds: [3],
        images: [],
        staffIds: [13],
        reviewIds: [], // todo
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 22,
        name: "Penis Enlargement ",
        title: "Penis Enlargement  Surgery ", // todo
        description: "", // todo
        pricePositionIds: [60, 62],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 23,
        name: "Lip Lift",
        title: "Lip Lift Surgery ", // todo
        description: "", // todo
        pricePositionIds: [29],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 24,
        name: "Malar Bags Removal",
        title: "Malar Bags Removal Surgery ", // todo
        description: "", // todo
        pricePositionIds: [54],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 25,
        name: "Sculpting for Men",
        title: "Sculpting for Men Surgery ", // todo
        description: "", // todo
        pricePositionIds: [58, 59],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 27,
        name: "Thigh Lift",
        title: "Thigh Lift Surgery ", // todo
        description: "", // todo
        pricePositionIds: [28],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 28,
        name: "Arm Lift",
        title: "Arm Lift Surgery ", // todo
        description: "", // todo
        pricePositionIds: [27],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [26],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 29,
        name: "Bichectomy Surgery",
        title: "Bichectomy Surgery Surgery ", // todo
        description: "", // todo
        pricePositionIds: [55],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 30,
        name: "Bra-Line Back Lift",
        title: "Bra-Line Back Lift Surgery ", // todo
        description: "", // todo
        pricePositionIds: [35],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 31,
        name: "Breast Lift",
        title: "Breast Lift Surgery ", // todo
        description: "", // todo
        pricePositionIds: [81, 84],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 32,
        name: "Breast Reduction",
        title: "Breast Reduction Surgery ", // todo
        description: "", // todo
        pricePositionIds: [85],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 33,
        name: "Brow Lift",
        title: "Brow Lift Surgery ", // todo
        description: "", // todo
        pricePositionIds: [44],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 34,
        name: "Calfs Implants",
        title: "Calfs Implants Surgery ", // todo
        description: "", // todo
        pricePositionIds: [32],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 35,
        name: "Chest Lift",
        title: "Chest Lift Surgery ", // todo
        description: "", // todo
        pricePositionIds: [26],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 36,
        name: "Chin Implants",
        title: "Chin Implants Surgery ", // todo
        description: "", // todo
        pricePositionIds: [33],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 37,
        name: "Gynecomastia",
        title: "Gynecomastia Surgery ", // todo
        description: "", // todo
        pricePositionIds: [56, 57],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 38,
        name: "Hairline Lowering",
        title: "Hairline Lowering Surgery ", // todo
        description: "", // todo
        pricePositionIds: [50],
        specializationIds: [5],
        images: [],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    },
    {
        id: 39,
        name: "360 Body Lift",
        title: "360 Body Lift Surgery ", // todo
        description: "", // todo
        pricePositionIds: [25],
        specializationIds: [5],
        images: [{ id: useUniqueId(), src: "/img/services/360_liposuction_1.webp", alt: "360 Liposuction before and after." }],
        staffIds: [1, 9, 10],
        reviewIds: [28],
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ],
        // todo faq's
        frequentlyAskedQuestions: {
            title: "",
            text: "",
            questions: []
        }
    }
];
