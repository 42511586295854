import type { ISpecialization } from "~/lib/specialization";
import { useUniqueId } from "~/utils/useUniqueId";
export const specializationListDto: ISpecialization[] = [
    {
        id: 1,
        title: 'General Surgery in Allmedica, Poland: "Precision Care for Every Patient."',
        description:
            "Experience thorough care and prompt recovery with a range of general surgical procedures, precisely tailored to meet diverse medical requirements with skill and compassion, supported by a united team committed to your well-being every step of the way.",
        keywords: "Allmedica, General Surgery, precision care, medical procedures, patient care, healthcare services",
        name: "General Surgery",
        staffIds: [5],
        serviceIds: [1, 2],
        avatar: "/img/specializations/general_surgery.png",
        order: 4,
        images: [],
        content: []
    },
    {
        id: 2,
        name: "Gynaecological Surgery",
        title: 'Gynaecological Surgery in Allmedica, Poland: "Dedicated to Women\'s Health and Wellness."',
        description:
            "Empower your feminine wellness with our specialized gynecological surgery services, focusing on vaginoplasty, labiaplasty, and more. Experience compassionate support and advanced treatments tailored to your needs, prioritizing your comfort and empowerment through clear communication and personalized interventions.", // todo
        keywords: "Allmedica, Gynaecological Surgery, women's health, vaginoplasty, labiaplasty, medical procedures, patient care",
        avatar: "/img/specializations/aesthetic-gynecology.png",
        order: 5,
        content: [],
        staffIds: [6],
        serviceIds: [17, 18, 19],
        images: []
    },
    {
        id: 3,
        name: "Bariatric Surgery",
        title: 'Bariatric Surgery in Allmedica, Poland: "Empowering Health, Inspiring Change."',
        description:
            "Take control of your health and well-being with our bariatric surgery options, tailored to support your weight loss goals and improve your overall quality of life, guided by dedicated professionals committed to your long-term success and wellness.",
        keywords: "Allmedica, Bariatric Surgery, weight loss, health improvement, medical procedures, patient care",
        avatar: "/img/specializations/bariatric_surgery.png",
        order: 2,
        content: [],
        staffIds: [13],
        serviceIds: [3, 4, 20],
        images: [
            {
                id: useUniqueId(),
                src: "/img/services/allmedica_perfection_during_bariatric_procedure.webp",
                alt: "Allmedica perfection during bariatric procedure"
            },
            { id: useUniqueId(), src: "/img/services/allmedica_poland_surgery_in_poland.webp", alt: "Allmedica poland surgery in poland" },
            { id: useUniqueId(), src: "/img/services/prof_major_allmedica_surgery_in_poland.webp", alt: "Prof major allmedica surgery in_poland" },
            { id: useUniqueId(), src: "/img/services/weight_loss_after_gastric_sleeve_1.webp", alt: "Weight loss after gastric sleeve" },
            { id: useUniqueId(), src: "/img/services/weight_loss_after_gastric_sleeve_2.webp", alt: "Weight loss after gastric sleeve" },
            { id: useUniqueId(), src: "/img/services/weight_loss_after_gastric_sleeve_3.webp", alt: "Weight loss after gastric sleeve" }
        ]
    },
    {
        id: 4,
        name: "Orthopedic Surgery",
        title: 'Orthopedic Surgery in Allmedica, Poland: "Restoring Movement, Renewing Life."',
        description:
            "Rediscover mobility and vitality through our orthopaedic surgery, designed to address a spectrum of conditions with compassionate care, reflecting our commitment to your well-being and ensuring optimal recovery and function restoration",
        keywords: "Allmedica, Orthopedic Surgery, mobility, vitality, medical procedures, patient care",
        avatar: "/img/specializations/orthopedic_surgery.png",
        order: 3,
        content: [],
        staffIds: [4, 8],
        serviceIds: [5, 6],
        images: []
    },
    {
        id: 5,
        name: "Plastic Surgery",
        title: 'Plastic Surgery in Allmedica, Poland: "Transforming Lives, One Procedure at a Time."',
        description:
            "Discover the simplicity of transformation with our plastic surgery solutions, combining professionalism, quality, and exceptional value. With our easy-as-1-2-3 approach, achieving your desired look with a guarantee of a hassle-free experience ensures satisfaction at every step.",
        keywords: "Allmedica, Plastic Surgery, transformation, medical procedures, patient care, healthcare services",
        avatar: "/img/specializations/plastic_surgery.png",
        order: 1,
        content: [],
        staffIds: [1, 9, 10],
        serviceIds: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
        images: [
            { id: useUniqueId(), src: "/img/services/abdominal_and_sides_liposuction.webp", alt: "Abdominal and sides liposuction" },
            { id: useUniqueId(), src: "/img/services/rhinoplasty_1.webp", alt: "Rhinoplasty surgery" },
            { id: useUniqueId(), src: "/img/services/upper_eyelid_surgery_with_eyebrow_lift_1.webp", alt: "Upper eyelid surgery with eyebrow lift" },
            { id: useUniqueId(), src: "/img/services/chin_lipo_16_days_post_surgery_1.webp", alt: "Chin lipo 16 days post surgery" },
            { id: useUniqueId(), src: "/img/services/360_liposuction_1.webp", alt: "360 Liposuction before and after." },
            { id: useUniqueId(), src: "/img/services/otoplasty_2_1.webp", fit: "contain", alt: "Otoplasty surgery" },
            { id: useUniqueId(), src: "/img/services/otoplasty_2_2.webp", fit: "contain", alt: "Otoplasty surgery" },
            { id: useUniqueId(), src: "/img/services/otoplasty_3.webp", fit: "contain", alt: "Otoplasty surgery" },
            { id: useUniqueId(), src: "/img/services/otoplasty_4.webp", fit: "contain", alt: "Otoplasty surgery" },
            { id: useUniqueId(), src: "/img/services/otoplasty_before_after.webp", fit: "contain", alt: "Otoplasty before after" },
            { id: useUniqueId(), src: "/img/services/otoplasty.webp", fit: "contain", alt: "Otoplasty" },
            { id: useUniqueId(), src: "/img/services/otoplasty_1.webp", fit: "contain", alt: "Otoplasty surgery" }
        ]
    }
];
