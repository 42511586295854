export const contactInfo = {
    phone: "+48 530 378 716",
    email: "operations@allmedica.pl",
    address: {
        street: "Aleja Tysiąclecia 111",
        sipCode: "34-400",
        city: "Nowy Targ",
        googleUrl: "https://maps.app.goo.gl/pNADLS5Abxd43QKVA"
    }
};
