import type { TRegionCode } from "./TRegionCode";

export interface IReview {
    id: number;
    name: string;
    countryCode: TRegionCode;
    rating: number;
    title: string;
    subTitle: string;
    text: string;
}

export class Review implements IReview {
    id;
    name;
    countryCode;
    rating;
    title;
    subTitle;
    text;

    constructor(dto: IReview) {
        this.id = dto.id;
        this.name = dto.name;
        this.countryCode = dto.countryCode;
        this.rating = dto.rating;
        this.title = dto.title;
        this.subTitle = dto.subTitle;
        this.text = dto.text;
    }
}

export class ReviewList {
    elements: Ref<Review[]>;

    constructor(dto: IReview[]) {
        this.elements = ref(dto.map((el) => new Review(el)) || []);
    }

    setElements(dto: IReview[]) {
        this.elements.value = dto.map((el) => new Review(el)) || [];
    }

    getElements() {
        return this.elements.value;
    }
}
