import type { IStaffItem } from "~/lib/staff";
import { useUniqueId } from "@/utils/useUniqueId";

export const staffListDto: IStaffItem[] = [
    {
        id: 1,
        avatarImageName: "dominik_boliglowa",
        name: "Dominik K. Boligłowa",
        medicalTitle: "Dr",
        specializationIds: [5],
        images: [
            { id: useUniqueId(), src: "/img/staff/boliglowa_image_1.webp", alt: "Dr Boliglowa surgery" },
            { id: useUniqueId(), src: "/img/staff/boliglowa_image_2.webp", alt: " Dr Boiglowa surgery" },
            { id: useUniqueId(), src: "/img/staff/boliglowa_image_3.webp", alt: "Dr Boliglowa surgery" },
            { id: useUniqueId(), src: "/img/staff/boliglowa_image_4.webp", alt: "Dr Boliglowa surgery" },
            { id: useUniqueId(), src: "/img/staff/boliglowa_image_5.webp", alt: "Dr Boliglowa surgery" },
            { id: useUniqueId(), src: "/img/staff/dr_boliglowa_bbl.webp", alt: "Dr boliglowa bbl" }
        ],
        description:
            "Meet Dr. Boligłowa, a highly skilled board certified plastic surgeon, trusted by celebrities. With an impressive yearly number of 400 surgeries, he is dedicated to precision and excellence. Specializing in facelifts and nose jobs, Dr. Boligłowa performs around 150 of nose jobs annually. He is also recognized for pioneering his unique technique in endoscopic transaxillary breast augmentation, which leaves patients without visible scars. Moreover, Dr Boligłowa is renowned for his contributions to scientific research and international conferences, where he presents live surgical demonstrations. Dr. Boligłowa's commitment to innovation and patient-centered care continues to redefine the standards of modern surgery.",
        meta: {
            title: "Dr. Dominik K. Boligłowa - Renowned Plastic Surgeon",
            description:
                "Meet Dr. Dominik K. Boligłowa, a leading plastic surgeon specializing in facelifts, rhinoplasty, and innovative breast augmentation techniques. Trusted by celebrities, Dr. Boligłowa combines precision and patient-centered care to deliver exceptional results.",
            keywords: "Dr. Dominik K. Boligłowa, plastic surgeon, facelift, rhinoplasty, breast augmentation, Allmedica, plastic surgery"
        },
        content: [
            {
                type: "title-2",
                text: "Education and Career Highlights:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: 'Graduated from the prestigious Surgery and Medical Department "Gemelli" at the Università Cattolica del Sacro Cuore in Rome in 2008.'
                    },
                    { text: "Began career in 2010 in Swiss medical centers in Mendrisio, Herisau, and Gstaad." },
                    { text: "Transitioned to Germany in 2013, obtaining specialization in plastic and aesthetic surgery." },
                    {
                        text: "Worked in Clinic of Plastic, Aesthetic, and Hand Surgery in Offenbach am Main, Germany, until September 2018 under Prof. Henrik Menke's supervision."
                    }
                ]
            },
            {
                type: "title-2",
                text: "Professional Development:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Actively pursues professional development through courses and training in aesthetic, reconstructive, and intimate surgery, as well as hand surgery and microsurgery."
                    },
                    {
                        text: "Completed internships with renowned specialists worldwide, including Dr. Maurizio Nava in Milan, Dr. Jose Luis Martin del Yerro Coca in Madrid, Prof. Joerg Gruenert in St. Gallen, Prof. Mario Pelle-Ceravolo in Rome, Dr. Michael Bromba in Essen, and Dr. Carlos del Pino Roxo in Rio de Janeiro."
                    }
                ]
            },
            {
                type: "title-2",
                text: "Professional Memberships:"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "Member of the Polish Society of Plastic, Reconstructive, and Aesthetic Surgery (PTChPRiE)." },
                    { text: "Member of the German Society of Plastic, Reconstructive, and Aesthetic Surgeons (DGPRÄC)." }
                ]
            },
            {
                type: "title-2",
                text: "Research and Publications:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Authored numerous scientific articles and presented at international conferences, where he also performed live surgical demonstrations."
                    },
                    { text: "Featured in media interviews, including appearances on morning shows and Radio Stations." }
                ]
            },
            {
                type: "title-2",
                text: "Discover Your Transformation:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Whether you're considering facial rejuvenation, body contouring, or breast enhancement, Dr. Boligłowa invites you to embark on a transformative journey. With his innovative techniques, comprehensive expertise, and dedication to patient satisfaction, he is committed to helping you achieve your desired aesthetic goals."
                    }
                ]
            },
            {
                type: "title-3",
                text: "Comprehensive Expertise:"
            },
            {
                type: "paragraph",
                text: "With a wealth of experience and a dedication to innovation, Dr. Boligłowa delivers personalized care and exceptional outcomes to each of his patients. Whether enhancing facial features or sculpting the body, his goal is to achieve natural-looking results that align with each patient's aesthetic goals."
            },
            {
                type: "title-3",
                text: "Experience and Credentials:"
            },
            {
                type: "paragraph",
                text: "Dr. Boligłowa has undergone extensive training and education in plastic surgery, ensuring that he remains at the forefront of his field. His commitment to excellence is evident in his clinical expertise, patient-centred approach, and continual pursuit of innovation."
            },
            {
                type: "paragraph",
                text: "Dr. Dominik K. Boligłowa is a specialist in plastic and reconstructive surgery. Dr. Boligłowa is a member of the Polish Society of Plastic, Reconstructive, and Aesthetic Surgery (PTChPRiE) and the German Society of Plastic, Reconstructive, and Aesthetic Surgeons (DGPRÄC)."
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: 'Graduated from the prestigious Surgery and Medical Department "Gemelli" at the Università Cattolica del Sacro Cuore in Rome in 2008.'
                    },
                    {
                        text: "Began career in 2010 in Swiss medical centers in Mendrisio, Herisau, and Gstaad."
                    },
                    {
                        text: "Transitioned to Germany in 2013, obtaining specialization in plastic and aesthetic surgery."
                    },
                    {
                        text: "Worked in Clinic of Plastic, Aesthetic, and Hand Surgery in Offenbach am Main, Germany, until September 2018 under Prof. Henrik Menke's supervision."
                    },
                    {
                        text: "Actively pursues professional development through courses and training in aesthetic, reconstructive, and intimate surgery, as well as hand surgery and microsurgery."
                    },
                    {
                        text: "Completed internships with renowned specialists worldwide, including Dr. Maurizio Nava in Milan, Dr. Jose Luis Martin del Yerro Coca in Madrid, Prof. Joerg Gruenert in St. Gallen, Prof. Mario Pelle-Ceravolo in Rome, Dr. Michael Bromba in Essen, and Dr. Carlos del Pino Roxo in Rio de Janeiro."
                    },
                    {
                        text: "Authored numerous scientific articles and presented at international conferences, where he also performed live surgical demonstrations."
                    },
                    {
                        text: "Featured in media interviews, including appearances on Dzień Dobry TVN and Radio Kraków."
                    },
                    {
                        text: "Fluent in Polish, English, German, Italian, Spanish, and Russian."
                    },
                    {
                        text: "Holds a personal interest in theatre and art, having studied at the vocal-acting department of the Academy of Music in Krakow and occasionally performed on stage until 2009."
                    }
                ]
            },
            {
                type: "title-3",
                text: "Specializations:"
            },
            {
                type: "paragraph",
                text: "Dr. Dominik K. Boligłowa specializes in facial plastic surgery, particularly facelifts, rhinoplasty, eyelid and ear corrections, as well as body aesthetic surgery, including breast plastic surgery, buttock augmentation (including the Brazilian butt lift method), and body contouring. Additionally, he offers intimate surgery procedures for both men (e.g., penis enlargement) and women (e.g., liposuction of the pubic mound and labiaplasty). He performs approximately 400 surgeries annually."
            },
            {
                type: "title-3",
                text: "Approach to Patient Care:"
            },
            {
                type: "paragraph",
                text: "Dr. Dominik K. Boligłowa adheres to the highest standards of safety and hygiene, providing personalized treatment methods and individualized care for each patient. He prioritizes patient health and satisfaction, tailoring treatment methods to meet the needs and health status of each individual. "
            }
        ]
    },
    {
        id: 9,
        avatarImageName: "lukasz_warchol",
        name: "Łukasz Warchoł",
        medicalTitle: "Dr.",
        specializationIds: [5],
        images: [
            { id: useUniqueId(), src: "/img/staff/allmedica_operation_in_poland_1.webp", alt: "Allmedica operation in poland 1" },
            { id: useUniqueId(), src: "/img/staff/allmedica_operation_procedure_breast.webp", alt: "Allmedica operation procedure breast" },
            { id: useUniqueId(), src: "/img/staff/allmedica_warchol_with_assistant.webp", alt: "Allmedica warchol with assistant" },
            { id: useUniqueId(), src: "/img/staff/breast_implants_dr_warchol.webp", alt: "Breast implants dr warchol" },
            { id: useUniqueId(), src: "/img/staff/dr_warchol_during_operation.webp", alt: "Dr warchol during operation" },
            { id: useUniqueId(), src: "/img/staff/dr_warchol_procedure_precision.webp", alt: "Dr warchol procedure precision" },
            { id: useUniqueId(), src: "/img/staff/dr_warchol_what_breast_implants_are_best.webp", alt: "Dr warchol what breast implants are best" },
            { id: useUniqueId(), src: "/img/staff/dr_warchol_with_patient_for_breast_implants.webp", alt: "Dr warchol with patient for breast implants" },
            { id: useUniqueId(), src: "/img/staff/what_are_the_best_breast_implants.webp", alt: "What are the best breast implants" }
        ],
        description:
            "Dr. Warchoł is a highly skilled plastic surgeon renowned for his expertise in transformative procedures, with a particular focus on breast augmentation through implants, and sculpting the body through advanced techniques such as liposuction and tummy tuck. In addition to these specialties, Dr. Warchoł also performs a wide range of other plastic surgeries, catering to diverse aesthetic goals and needs.",
        content: [
            {
                type: "title-2",
                text: "Education and Training:"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "Graduated from the Faculty of Medicine at the Jagiellonian University Medical College in Krakow." },
                    { text: "Graduated from Ludwig-Maximilians-Universität in Munich." },
                    {
                        text: "Completed specialization training in plastic surgery at:",
                        nested: [
                            { text: "Plastic Surgery Department of L. Rydygier Specialist Hospital in Krakow." },
                            { text: "Clinical Department of Plastic, Reconstructive, and Aesthetic Surgery at University Clinical Hospital No. 1 in Łódź." }
                        ]
                    }
                ]
            },
            {
                type: "title-2",
                text: "Experience:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Clinical internships at:",
                        nested: [
                            { text: "University of California, San Diego." },
                            { text: "Akademikliniken in Stockholm." },
                            { text: "Mahidol University in Bangkok." }
                        ]
                    }
                ]
            },
            {
                type: "title-2",
                text: "Professional Memberships:"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "Member of the International Society of Aesthetic Plastic Surgery (ISAPS)." },
                    { text: "Member of the Polish Society of Plastic, Reconstructive, and Aesthetic Surgery (PTChPRiE)." }
                ]
            },

            {
                type: "title-2",
                text: "Continuing Education:"
            },

            {
                type: "unordered-list",
                items: [
                    { text: "Participation in numerous national and international training sessions in the Netherlands, Germany, Sweden, Turkey, and Italy." }
                ]
            },
            {
                type: "title-2",
                text: "Research and Publications:"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "Authored scientific publications in Polish and international journals." },
                    {
                        text: 'Defended doctoral thesis titled "Variability of the medial plantar artery branch departure and division of the tibial nerve. Macroscopic and microscopic analysis."'
                    }
                ]
            },
            {
                type: "title-2",
                text: "Trust and Recognition: "
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Dr. Warchoł's commitment to patient safety, satisfaction, and stunning results has earned him trust and recognition within the medical community and among his grateful patients. His reputation for excellence precedes him, making him a top choice for individuals seeking transformative plastic surgery procedures."
                    }
                ]
            },
            {
                type: "title-2",
                text: "Attention to Detail, Confidence in Results: "
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "With a passion for precision and an unwavering commitment to achieving exceptional outcomes, Dr. Warchoł brings technical expertise to each procedure. Whether it's enhancing the contours of the body or restoring confidence through breast augmentation, his patients trust in his ability to deliver natural-looking results that exceed "
                    }
                ]
            },
            {
                type: "title-2",
                text: "Procedural Volume:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Dr. Warchoł's extensive experience and proficiency in the field are evidenced by the hundreds of procedures he performs annually."
                    }
                ]
            },
            {
                type: "title-2",
                text: "Discover Your Transformation: "
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Whether you're considering breast enhancement, body contouring, or another cosmetic procedure, Dr. Warchoł invites you to take the first step towards achieving your aesthetic goals. With his skill, dedication, and patient-centred approach, he is committed to helping you look and feel your absolute best."
                    }
                ]
            }
        ]
    },
    {
        id: 10,
        avatarImageName: "michal_pasinski",
        name: "Michał Pasiński",
        medicalTitle: "Dr.",
        specializationIds: [5],
        images: [],
        description:
            "Dr. Pasiński is a Doctor of Medicine with 17 years of experience with general and plastic surgery - double board certification. Completed internships, training and courses in health care facilities in Poland and abroad e.g. Brazil and Spain. Member of the Polish Society of Plastic, Reconstructive, Aesthetic Surgery and the Polish Society of Surgeons and ISAPS. He performs all plastic surgery procedures e.g. liposuction and breast implants, offering solutions to enhance body contours and achieve aesthetic goals.",
        content: [
            {
                type: "title-2",
                text: "Education:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Specialization training in plastic surgery at the Clinical Department of Plastic Surgery, Reconstructive Surgery, and Burn Treatment, Military Institute of Medicine – National Research Institute in Warsaw, Poland; Dr. Rafael Milani in Caxias do Sul in Brazil; Rydgier Specialist Hospital in Krakow, Poland"
                    },
                    {
                        text: "Specialization in general surgery initially at the Brothers Hospitallers of Saint John of God Hospital in Krakow, Poland; Hospital Virgen del Camino in Cadiz, Spain; Hospital Juan Grande in Jerez, Spain"
                    },
                    { text: "Completed international internships, including at Hospital São Lucas da PUCRS in Porto Alegre, Brazil." },
                    { text: "Graduated in 2004 from the Medical Faculty of Jagiellonian University in Krakow." }
                ]
            },
            {
                type: "title-2",
                text: "Experience:"
            },
            {
                type: "title-3",
                text: "Acquired experience working at various hospitals, including:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Clinical Department of Plastic Surgery, Reconstructive Surgery, and Burn Treatment, Military Institute of Medicine – National Research Institute in Warsaw, Poland"
                    },
                    { text: "The University Hospital in Krakow, Poland" },
                    { text: "Boni Fratres Catoviensis Hospital in Katowice, Poland" },
                    { text: "SP ZOZ MSWiA in Krakow, Poland" }
                ]
            },
            {
                type: "title-3",
                text: "SCANMED Saint Raphael Hospital in Krakow, Poland Courses and Certifications:"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "2022 - Mandala Beauty Clinic | Surgical Modeling of the buttocks" },
                    { text: "2021 - PUM, Microsurgery" },
                    { text: "2021 - PUM, Hand Surgery & Microsurgery" },
                    { text: "2018, 2013, 2006 - UMS, Advanced Cardiovascular Life Support" },
                    { text: "2017 - ACS, Advanced Surgical Skills for Exposure in Trauma Course" },
                    { text: "2012 - UM Wroclaw, Plastic & Reconstructive Surgery, introduction to Microsurgery" },
                    { text: "2011 - UJ, Surgical Anastomosis Techniques" },
                    { text: "2009 - SUM, Introduction to Transplant Surgery" },
                    { text: "2008 - CSW, Patient Radiation Protection" },
                    { text: "2008 - SUM, Burns Treatments" },
                    { text: "2007 - AM Warsaw, Combined Cancer Treatment" },
                    { text: "2006 - UMS, Pediatric Advanced Life Support" }
                ]
            },
            {
                type: "paragraph",
                text: "Dr. Pasinski prioritizes understanding each patient’s unique desires and concerns, creating a supportive environment throughout their treatment journey. His international training experiences have broadened his practice, exposing him to diverse cultures and medical techniques. With internships, trainings, and courses in Brazil and Spain, Dr. Pasinski has refined his skills to deliver comprehensive and personalized care. Consult in Polish, English, Spanish, Portuguese, and Russian, Dr. Pasinski facilitates effective communication with his diverse patient base, further enhancing the patient experience."
            }
        ]
    },
    {
        id: 13,
        avatarImageName: "piotr_major",
        name: "Piotr Major",
        medicalTitle: "PhD",
        specializationIds: [3],
        images: [
            {
                id: useUniqueId(),
                src: "/img/services/allmedica_perfection_during_bariatric_procedure.webp",
                alt: "Allmedica perfection during bariatric procedure"
            },
            { id: useUniqueId(), src: "/img/services/allmedica_poland_surgery_in_poland.webp", alt: "Allmedica poland surgery in poland" },
            { id: useUniqueId(), src: "/img/services/prof_major_allmedica_surgery_in_poland.webp", alt: "Prof major allmedica surgery in_poland" }
        ],
        description:
            "Professor Piotr Major, PhD, is a distinguished bariatric surgeon and academic at Jagiellonian University Collegium Medicum, specializing in minimally invasive techniques for gastrointestinal surgery. With a rich educational background, including an MBA in Healthcare and extensive professional qualifications, he has made significant contributions to the field of bariatric and oncological surgery. Currently, as the Coordinator of the Obesity Surgical Treatment Center at the University Hospital in Krakow, Professor Major continues to lead advancements in surgical treatments for obesity and cancer.",
        content: [
            {
                type: "title-2",
                text: "Education:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: '2021 - 2023 Postgraduate studies "MBA in Healthcare", Cracow University of Economics, Cracow Business School'
                    },
                    {
                        text: "2010 – 2014 PhD studies at the Faculty of Medicine, Jagiellonian University Collegium Medicum in Krakow"
                    },
                    { text: "2003 – 2009 Medical studies at the Faculty of Medicine, Jagiellonian University Collegium Medicum in Krakow" }
                ]
            },
            {
                type: "title-2",
                text: "Professional qualification:"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "2 November 2020 Specialization in the oncological surgery head of specialization: Piotr Bodzioch" },
                    { text: "27 September 2017 European Specialization in General Surgery (Fellow of the European Board of Surgery, FEBS)" },
                    { text: "7 April 2017 Specialization in general surgery head of specialization: Marek Winiarski, PhD" },
                    {
                        text: "22 December 2016 Certificate authorizing to perform procedures in the field of diagnostic and surgical endoscopy of the gastrointestinal tract issued by the Board of the Endoscopic Section of TChP"
                    }
                ]
            },
            {
                type: "title-2",
                text: "Professional career:"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "since 2021 – currently  Coordinator of the Obesity Surgical Treatment Center at the University Hospital in Krakow" },
                    { text: "since 2020 – currently Professor, II Department of General Surgery, Jagiellonian University Collegium Medicum" },
                    { text: "since 2019 – 2020 University professor, II Department of General Surgery, Jagiellonian University Collegium Medicum" },
                    {
                        text: "since 2015 – 2019 Assistant professor with the degree of habilitated doctor, II Department of General Surgery, Jagiellonian University Collegium Medicum"
                    },
                    {
                        text: "since 2010 – currently Senior assistant at the Department of General, Oncological, Metabolic and Emergency Surgery, University Hospital in Krakow from 2016 to 2021 Program manager, Medical Center for Postgraduate Education of the Jagiellonian University"
                    }
                ]
            },
            {
                type: "title-2",
                text: "Scientific activity:"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "November 26, 2020  - The title of professor of medical sciences and health sciences in the discipline of medical sciences" },
                    {
                        text: '19 October 2017 Academic degree of habilitated Doctor of Medical Sciences based on the series of publications entitled "Selected factors affecting the safety of bariatric operations in patients treated surgically for giant obesity". Jagiellonian University Collegium Medicum Krakow'
                    },
                    {
                        text: '25 September 2014 Doctoral degree in medical sciences based on the doctoral dissertation entitled "Quality of life of patients after surgical treatment of giant obesity". Jagiellonian University Collegium Medicum Krakow'
                    },
                    {
                        text: `<div>until January 2021 - Author and co-author of 195 scientific publications.</div>
                            <div>Author and co-author of over 226 congress reports.</div>
                            <div>Total Impact Factor (IF) = 526.299</div>
                            <div>Web of Science Hirsh Index 24</div>
                            <div>Total number of citations according to Web of Science 2013</div>`
                    }
                ]
            },
            {
                type: "title-2",
                text: "Main professional and scientific interests:"
            },
            {
                type: "paragraph",
                text: "The main area of my work are minimally invasive surgical techniques used in gastrointestinal surgery (metabolic and bariatric surgery, oncological surgery). In the center of my professional and scientific interests are issues related to the surgical treatment of patients with giant obesity (bariatric and metabolic surgery). Minimally invasive oncological surgery (colorectal surgery and gastric surgery) is the second group of my clinical interests. Comprehensive and coordinated treatment, the possibilities of using the protocol of modern perioperative care (ERAS - Enhanced Recovery After Surgery) are the third largest area of my professional and scientific activity."
            },
            {
                type: "title-2",
                text: "Research grants:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "2018/02/X/NZ4/00192 (MINIATURA): Selected aspects regarding the use of gastric balloon as a method supporting surgical treatment of obesity (project leader)"
                    },
                    {
                        text: "NCN/2011/03/D/NZ5/00551 (SONATA) The study of intestinal flora in patients with diabetes type 1 and 2 and with morbid obesity or pathological undergoing sleeve gastrectomy (project developer)"
                    },
                    {
                        text: 'Grant of the Ministry of Science and Higher Education "Diamond Grant 2018"'
                    },
                    {
                        text: 'Grant of the Ministry of Science and Higher Education "Diamond Grant 2019"'
                    }
                ]
            },
            {
                type: "title-2",
                text: "Functions performed:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "since 2019 Member of the Scientific Council of the Medical Sciences Discipline of the Jagiellonian University"
                    },
                    {
                        text: "since 2018 Member of the Bioethics Committee of the Jagiellonian University "
                    },
                    {
                        text: "since 2017 Member of the Management Board of the Bariatric and Metabolic Surgery Section of the Society of Polish Surgeons"
                    },
                    {
                        text: "since 2017 Member of the Board of the Videosurgery Section of the Society of Polish Surgeons"
                    }
                ]
            },
            {
                type: "title-2",
                text: "Foreign trips and internships:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "April 2023 Medical University of Vienna, Department of General Surgery, Vienna, Austria (Prof. Gerhard Prager)"
                    },
                    {
                        text: "July 2023 Clevelenad Clinic Abu Dhabi, United Arab Emirates (Dr. Javed Raza, Dr. John Rodriquez)"
                    },
                    {
                        text: "March 2022 Department of Bariatric Surgery, AZ Sint-Jan-Hospital Department Bruges, Belgium (Prof. Bruno Dilemans)"
                    },
                    {
                        text: "October 2018 Department of Bariatric Surgery, AZ Sint-Jan-Hospital Department Bruges, Belgium (prof. Bruno Dilemans)"
                    },
                    {
                        text: "July 2018 Department of Bariatric Surgery, Hospital Universitario Bellvitge Barcelona, Spain"
                    },
                    {
                        text: "November 2016 Department of Bariatric Surgery, Homerton University Hospital, London, UK (Prof. Kesava Mannur)"
                    },
                    {
                        text: "October 2015 Ludwig Maximillians Universität, Munich, Germany (Prof. Matthias Siebeck)"
                    },
                    {
                        text: "September 2014 Scholarship of the Society of Polish Surgeons, University of Health and Welfare, Tokyo, Japan(prof. Masaki Kitajima)"
                    }
                ]
            },
            {
                type: "title-2",
                text: "Research and medical education courses and trainings:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "May 2017 Introduction to the methodology of developing systematic reviews, Centre for Systematic Reviews – Polish Branch of Cochrane UJ CM"
                    },
                    {
                        text: "November 2016 Good Clinical Practices, NIDA Clinical Trials Network"
                    },
                    {
                        text: "September 2015 How to get your paper published, Post Graduate Course, 20th World Congress of International Federation for the Surgery of Obesity and Related Diseases (IFSO), Vienna, Austria"
                    },
                    {
                        text: "November 2015 Medical Education Training: Academic Teacher Training Course, Ludwig Maximilians-Universität, Munich, Germany"
                    }
                ]
            },
            {
                type: "title-2",
                text: "Membership in scientific societies:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Society of Polish Surgeons (TChP):",
                        nested: [
                            { text: "Member of the Board Section of Bariatric and Metabolic Surgery" },
                            { text: "Member of the Board of the Videosurgery Section" },
                            { text: "TChP Endoscopic Surgery Section" }
                        ]
                    },
                    {
                        text: "International Federation for the Surgery of Obesity and Metabolic Disorders (IFSO)"
                    },
                    {
                        text: "The European Association for Endoscopic Surgery (EAES)"
                    }
                ]
            },
            {
                type: "title-2",
                text: "Awards, distinctions, scholarships:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "July 2021 Second prize in the competition of the Polish Surgical Review for the best scientific article of the META-ANALYSIS type"
                    },
                    {
                        text: "November 2020 Award of the Rector of UJCM II degree for organizational activity in the academic year 2018/2019"
                    },
                    {
                        text: "December 2018 Award of the Minister of Science and Higher Education: first degree individual award for scientific achievement"
                    },
                    {
                        text: "October 2018 Scholarship of the Minister of Science and Higher Education for outstanding young scientists awarded for the period 2018-2021"
                    },
                    {
                        text: "October 2018 Award of the Rector of UJCM for scientific achievements in the academic year 2017/2018"
                    },
                    {
                        text: "June 2018 Prof. Marek Sycha Award granted by the Krakow Medical Society for scientific achievements being the basis for habilitation."
                    },
                    {
                        text: "October 2017 Award of the Rector of UJCM for scientific achievements in the academic year 2016/2017"
                    },
                    {
                        text: "October 2017 Award of the Rector of UJCM III degree for organizational activity in the academic year 2016/2017"
                    },
                    {
                        text: "October 2017 Award of the Regional Medical Chamber in Krakow for the best passed specialization exam in general surgery"
                    },
                    {
                        text: 'March 2016 The Harold Ellis Prize for the best original work published in the renowned journal "The International Journal of Surgery"'
                    },
                    {
                        text: "May 2016 First Prize, National Interdisciplinary Surgical Conference for Students and PhD Students"
                    },
                    {
                        text: "June 2016 First Prize, 23rd International Student Congress of (bio)Medical Sciences, Groningen, The Netherlands (supervisor)"
                    },
                    {
                        text: "September 2016 Second Prize, Surgical Session, International Medical Congress of Silesia (supervisor)"
                    },
                    {
                        text: "October 2016 Third prize, Surgical Session of the International Conference of Medical University Students (thesis supervisor)"
                    },
                    {
                        text: "September 2015 The main prize during the 67. Congress of the Society of Polish Surgeons in Lublin for the best scientific work"
                    },
                    {
                        text: "September 2015 Honorable Mention during the 67. TChP Congress in Lublin for the best report in the field of bowel surgery"
                    },
                    {
                        text: "June 2014 Scholarship of the Society of Polish Surgeons"
                    }
                ]
            },
            {
                type: "title-2",
                text: "Participation in selected conferences and trainings in the field of surgical treatment of obesity:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "IFSO 2023 – Naples, Italy"
                    },
                    {
                        text: "EAES 2022 – Krakow, Poland"
                    },
                    {
                        text: "SAGES – Baltimore, USA 2021"
                    },
                    {
                        text: "IFSO 2019 – Madrid, Spain"
                    },
                    {
                        text: "Advanced Course in bariatric surgery – SADI, Barcelona 2018"
                    },
                    {
                        text: "Minimal Invasive Techniques for Bariatric & Metabolic Surgery, Hamburg, Germany 2018"
                    },
                    {
                        text: "360 degree around bariatric and metabolic surgery, Lodz 2018"
                    },
                    {
                        text: "III Annual Conference: Current trends in bariatric surgery, KCM Clinic, Jelenia Góra 2018"
                    },
                    {
                        text: "Practical Medicine Surgery, Kraków 2018"
                    },
                    {
                        text: "IFSO 2017 - London, UK"
                    },
                    {
                        text: "IFSO 2016 – Rio de Janeiro, Brazil"
                    },
                    {
                        text: "IFSO 2015 – Vienna, Austria"
                    },
                    {
                        text: "IFSO 2014 – Montreal, Canada"
                    },
                    {
                        text: "IFSO 2013 – Istanbul, Turkey"
                    }
                ]
            }
        ]
    },
    {
        id: 0,
        avatarImageName: "wojciech_kacki",
        name: "Wojciech Kącki",
        medicalTitle: "MD. Ph.D.",
        specializationIds: [4],
        images: [],
        meta: {
            title: "Dr. Wojciech Kącki - Expert Orthopedic Surgeon at Allmedica",
            description:
                "Discover Dr. Wojciech Kącki, a highly skilled orthopedic surgeon with over 30 years of experience in advanced orthopedic surgery. Specializing in hip, knee, and foot surgeries, Dr. Kącki is dedicated to providing top-quality care at Allmedica.",
            keywords: "Dr. Wojciech Kącki, orthopedic surgeon, orthopedic surgery, hip surgery, knee surgery, foot surgery, Allmedica"
        },
        description:
            "A highly skilled, dedicated and detail-oriented physician with a passion for advanced, modern Orthopedic Surgery, utilizing skills and experience to contribute effectively to a dynamic team at the Allmedica.",
        content: [
            {
                type: "title-2",
                text: "Education:"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "<b>MD</b> (Medical Doctor). Graduated from Silesian Academy of Medicine in Katowice, the largest medical school in Poland." },
                    { text: "<b>Second-degree specialization</b> in Orthopaedics and Traumatology." }
                ]
            },
            {
                type: "title-2",
                text: "Professional Experience:"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "1990 – renowned Silesian Center of Rheumatology, Orthopedics and Rehabilitation in Ustroń – <b>assistant</b>" },
                    {
                        text: "1992 – present - Department of Orthopaedics, the University Orhopedics and Rehabilitation Hospital in Zakopane at the prestigious Jagiellonian University Medical College – <b>older assistant</b>"
                    },
                    { text: "2000 – present - Jagiellonian University Medical College, Department and  Orthopaedics Clinic – lecteurer" },
                    { text: "2023– present – the <b>Head</b> of Mountain Medicine Research at the Jagiellonian University Medical College" }
                ]
            },
            {
                type: "title-2",
                text: "Professional experience and interests:"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "<b>Adult and children Orthopaedicswith</b> over 6000 operations performed" },
                    { text: "Expertise in primary and revision <b>arthroplastyof</b> of <b>hip</b>, <b>knee</b>, <b>ankle</b>, and <b>small foot joints</b>." },
                    {
                        text: "Expertise in treatments of <b>bone union disorders</b>, <b>limb elongationand</b> and <b>deformity correction</b>, <b>foot</b> disorders, and various orthopedic surgeries."
                    }
                ]
            },
            {
                type: "title-2",
                text: "Teaching and Academic appointments:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "<b>Lecturerat</b> at the <b>Jagiellonian University Medical College</b> in Krakow for physicians,rehabilitants, and students of medicine."
                    },
                    { text: "<b>Lecturerat</b> at the <b>University of Technology</b> in Cracow, Wroclaw, and Czestochowa for bioengineering students." }
                ]
            },
            {
                type: "title-2",
                text: "Achievements:"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "Distinguished Author and co-author of over 80 publications in scientific journals with multiple conference presentations." },
                    { text: "Participation in numerous medical congresses, conferences, and professional courses." }
                ]
            },
            {
                type: "title-2",
                text: "Memberships:"
            },
            {
                type: "unordered-list",
                items: [
                    { text: "Member of the <b>Polish Orthopaedics and Traumatological Association</b> (PTOiTR)." },
                    {
                        text: "Member of the <b>Polish Pediatric Orthopaedics Association</b>, serving three terms as a member and <b>chairman</b> of the <b>Scientific Committee</b>."
                    },
                    {
                        text: "Member of the <b>Polish Society of Spine Surgery</b>, holding the position of <b>Secretary</b> of the Main Board for four terms."
                    },
                    {
                        text: "Member of the <b>Association for the Study and Application of the Methods of Ilizarov</b> (ASAMI) Poland, currently serving as <b>President</b> of the Main Board."
                    },
                    { text: "Member of the <b>Polish Society of Biomedical Engineering</b>." }
                ]
            },
            {
                type: "title-2",
                text: "Languages:"
            },
            {
                type: "unordered-list",
                items: [{ text: "<b>Polish</b> – native" }, { text: "Fluent in <b>English</b>" }]
            }
        ]
    },
    {
        id: 4,
        avatarImageName: "jakub_kalisz",
        name: "Jakub Kalisz",
        medicalTitle: "MD",
        specializationIds: [4],
        images: [],
        description:
            "An exceptionally skilled and dedicated physician with a strong passion for advanced and modern orthopedic surgery. With a meticulous approach and a wealth of experience, this physician excels in utilizing his expertise to contribute effectively to the dynamic and innovative team at Allmedica. Committed to delivering the highest standard of patient care, he combines his knowledge and skills to ensure successful surgical outcomes and improve patients' quality of life. His dedication to continuous learning and professional development ensures he stays at the forefront of orthopaedic advancements, making them a valuable asset to the Allmedica team.",
        meta: {
            title: "Dr. Jakub Kalisz - Experienced Orthopedic Surgeon at Allmedica",
            description:
                "Explore the expertise of Dr. Jakub Kalisz, an accomplished orthopedic surgeon specializing in arthroplasty and orthopedic surgeries. With a dedication to advanced surgical techniques, Dr. Kalisz provides outstanding orthopedic care at Allmedica.",
            keywords: "Dr. Jakub Kalisz, orthopedic surgeon, arthroplasty, orthopedic surgeries, Allmedica"
        },
        content: [
            {
                type: "title-2",
                text: "Education:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "MD (Medical Doctor). Graduated from Jagiellonian University Medical College in Krakow, the oldest medical school in Poland."
                    },
                    {
                        text: "Specialization in Orthopaedics and Traumatology."
                    }
                ]
            },
            {
                type: "title-2",
                text: "Professional Experience:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "2013 – present - Department of Orthopaedics, the University Orhopedics and Rehabilitation Hospital in Zakopane at the prestigious Jagiellonian University Medical College – older assistant"
                    },
                    {
                        text: "2018 – present  - Jagiellonian University Medical College, Department and  Orthopaedics Clinic - lecteurer"
                    }
                ]
            },
            {
                type: "title-2",
                text: "Professional experience and interests:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Adult and children Orthopaedics with over 200 operations performed per year."
                    },
                    {
                        text: "Expertise in primary and revision arthroplasty of hip, knee, ankle, and foot joints."
                    },
                    {
                        text: "Expertise in arthroscopy of shoulder, knee and ankle; foot disorders, and various orthopedic surgeries including spine surgery."
                    }
                ]
            },
            {
                type: "title-2",
                text: "Teaching and Academic appointments:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Lecturer at the Jagiellonian University Medical College in Krakow for physicians and students of medicine."
                    }
                ]
            },
            {
                type: "paragraph",
                text: "Participation in numerous medical congresses, conferences, and professional courses."
            },
            {
                type: "title-2",
                text: "Memberships:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Member of the Polish Orthopaedics and Traumatological Association (PTOiTR)."
                    }
                ]
            },
            {
                type: "title-2",
                text: "Languages:"
            },
            {
                type: "unordered-list",
                items: [{ text: "Polish – native" }, { text: "Fluent in English" }]
            }
        ]
    },
    {
        id: 8,
        avatarImageName: "katarzyna_kacka",
        name: "Katarzyna Kącka",
        medicalTitle: "Dr.",
        specializationIds: [4],
        images: [],
        description: "", // todo
        // todo content
        content: [
            {
                type: "title-2",
                text: "Content coming soon."
            },
            {
                type: "cta",
                title: "Talk to a specialist.",
                text: "Contact our team.",
                ctaText: "Get In Touch"
            }
        ]
    },
    {
        id: 5,
        avatarImageName: "dariusz_cichostepski",
        name: "Dariusz Cichostępski",
        medicalTitle: "Dr",
        specializationIds: [1],
        images: [],
        description:
            "Dr. Cichostępski's professional interests are surgical procedures, including laparoscopic and open surgeries for gallbladder, hernias, and gastrointestinal issues. He also focuses on modern methodologies for chronic venous insufficiency treatment and performs anastomosis procedures for dialysis, showcasing his commitment to vascular health. Dr. Cichostępski performs approximately 300 surgeries annually, accumulating a total of 6000 surgeries throughout his career, ensuring that each patient receives high-quality, personalized care.",
        meta: {
            title: "Dr. Dariusz Cichostępski - Expert General and Vascular Surgeon",
            description:
                "Learn about Dr. Dariusz Cichostępski, a highly experienced general and vascular surgeon specializing in hernia repair, gallbladder surgeries, and advanced treatments for venous insufficiency. Committed to personalized patient care at Allmedica.",
            keywords: "Dr. Dariusz Cichostępski, general surgeon, vascular surgeon, hernia repair, gallbladder surgery, venous insufficiency, Allmedica"
        },
        content: [
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Graduated in 2002 from the Medical University of Silesia in Katowice, the largest medical school in Poland."
                    },
                    {
                        text: "Brings over 22 years of extensive medical experience to his practice."
                    },
                    {
                        text: "Holds the positions of Senior Assistant Doctor and Head Doctor in the Emergency Department at the General and Vascular Surgery Department of the Specialistic Hospital in Nowy Sacz."
                    },
                    {
                        text: "Fluent in Polish and English"
                    },
                    {
                        text: "Specializes in general surgery, with particular expertise in:",
                        nested: [{ text: "Hernia repair." }, { text: "Gallbladder Removal." }, { text: "Gastrointestinal surgeries." }]
                    },
                    {
                        text: "Interested in vascular surgery, particularly in:",
                        nested: [
                            { text: "Modern methodologies for treating chronic venous insufficiency." },
                            { text: "Performing anastomosis procedures for dialysis patients." }
                        ]
                    },
                    {
                        text: "Known for his meticulous approach to surgical interventions and commitment to staying updated with the latest advancements in his field."
                    }
                ]
            },
            {
                type: "paragraph",
                text: "Dr. Dariusz Cichostępski is a distinguished graduate of the Silesian Medical Academy, boasting over 22 years of invaluable medical experience. Throughout his illustrious career, he has been deeply immersed in the field of surgery, particularly within the realms of general and vascular surgery. Dr. Cichostępski's journey in medicine has seen him serve prominently at the General and Vascular Surgery Department of the Specialistic Hospital in Nowy Sacz. Currently, he holds the esteemed positions of Senior Assistant Doctor and Head Doctor in the Emergency Department at this renowned institution."
            },
            {
                type: "paragraph",
                text: "As a specialist in general surgery, Dr. Cichostępski's professional interests encompass a wide array of surgical procedures. His expertise spans laparoscopic and open gallbladder and biliary duct surgeries, hernia surgeries, and gastrointestinal surgeries. Within the domain of vascular surgery, he displays a keen interest in the application of modern methodologies for treating chronic venous insufficiency. Additionally, he is skilled in performing anastomosis procedures for dialysis, showcasing his commitment to advancing patient care in this critical area of vascular health."
            },
            {
                type: "paragraph",
                text: "Dr. Cichostępski's unwavering dedication to his patients is evident in his meticulous approach to surgical interventions and his steadfast commitment to staying abreast of the latest advancements in his field. His proficiency, coupled with his compassionate demeanor, has earned him the respect and admiration of both colleagues and patients alike, cementing his status as a trusted authority in the field of surgery."
            }
        ]
    },
    {
        id: 6,
        avatarImageName: "dariusz_komorowski",
        name: "Dariusz Komorowski",
        medicalTitle: "Dr",
        specializationIds: [2],
        images: [],
        description:
            "Dr. Komorowski specializes in procedures such as labiaplasty and vaginoplasty. Performing approximately 600 surgeries annually, he ensures that each patient receives high-quality, personalized care. Dr. Komorowski's dedication to continual professional development ensures that he stays at the forefront of his field, delivering exceptional outcomes to his patients. ",
        content: [
            {
                type: "title-2",
                text: "Education and Specialization:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Graduatedin 1991 from the Military Medical Academy in Łodz."
                    },
                    {}
                ]
            },
            {
                type: "title-2",
                text: "Experience:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Specialization in gynecology and obstetrics at the Gynecological Department of the Military Hospital in Krakow."
                    },
                    {
                        text: "Specialization at the Szczeklik Provincial Hospital in Tarnów."
                    },
                    {
                        text: "SP ZOZ Brzesko in the Gynecological and Obstetric Department."
                    },
                    {
                        text: "Deputy Chief Resident at the SP ZOZ Brzesko in the Gynecological and Obstetric Department."
                    },
                    {
                        text: 'Deputy Head of Treatment Affairs at NZOZ "MEDYK II" in Iwkowa.'
                    },
                    {
                        text: "Chief of the Gynecological and Obstetric Department in Mielec."
                    },
                    {
                        text: "Senior Assistant at MEDIKOR Hospital in Nowy Sącz."
                    }
                ]
            },
            {
                type: "title-2",
                text: "Additional Certifications and Courses:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Certified in advanced urogynecology, Certified in gynaecological laparoscopy, Certified in breastfeeding support, Certified in cryosurgery, Certified in fetal echocardiography."
                    },
                    {
                        text: "Training and courses in the country and abroad, including Holland and Czechia"
                    }
                ]
            },
            {
                type: "title-2",
                text: "Surgical Expertise:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Conducts various gynecological surgeries, including labiaplasty and vaginoplasty."
                    }
                ]
            },
            {
                type: "title-2",
                text: "Professional Attributes:"
            },
            {
                type: "unordered-list",
                items: [
                    {
                        text: "Highly qualified and compassionate medical professional."
                    },
                    {
                        text: "Committed to expanding knowledge and skill set to provide comprehensive and personalized care."
                    },
                    {
                        text: "Fluent in Polish, English, Russian"
                    }
                ]
            }
        ]
    },
    {
        id: 14,
        avatarImageName: "beata_kulach",
        name: "Beata Kulach",
        medicalTitle: "Business Unit Manager - Medical Tourism",
        staffType: "bok",
        specializationIds: [],
        images: [],
        description:
            "Beata Kulach has been part of the Allmedica team since 2022. With fluent English and 18 years of professional experience in the US, she easily understands the needs of international patients. Beata loves working with people and has traveled extensively, bringing valuable experience to her role in providing exceptional care and support to patients seeking medical treatment abroad. She fosters seamless coordination and delivers exceptional service to patients seeking medical treatment abroad.",
        content: [
            {
                type: "contact",
                text: "Contact:",
                email: "beata.kulach@allmedica.pl",
                phone: "+48 530 524 103"
            }
        ]
    },
    {
        id: 11,
        avatarImageName: "magdalena_czarnecka",
        name: "Magdalena Czarnecka",
        staffType: "bok",
        medicalTitle: "Patient Services Manager",
        specializationIds: [],
        images: [],
        description:
            "Magdalena is the first point of contact with the clinic. From scheduling appointments to coordinating treatments and managing inquiries she strives to maintain high standards of patient satisfaction and care. By implementing effective communication channels she aims to enhance the overall patient experience.",
        content: [
            {
                type: "contact",
                text: "Contact:",
                email: "operations@Allmedica.pl",
                phone: "+48 530 378 716"
            }
        ]
    },
    {
        id: 12,
        avatarImageName: "oskar_garbacz",
        name: "Oskar Garbacz",
        medicalTitle: "Host",
        staffType: "bok",
        specializationIds: [],
        images: [],
        description:
            "Oskar Garbacz serves as the International Patients Host at Allmedica since 2019. With fluency in English, he ensures seamless communication and support for international patients, facilitating their journey and providing a comfortable experience throughout their treatment process.",
        content: [
            {
                type: "contact",
                text: "Contact:",
                email: "oskar.garbacz@allmedica.pl",
                phone: "+48 535 597 992"
            }
        ]
    }
];
