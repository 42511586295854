import type { IContentItem } from "~/lib/cmsContent";
import type { ISlide } from "~/lib/slide";
import { priceList, reviewList } from "~/state/state";

export interface IService {
    id: number;
    name: string;
    title: string;
    subTitle?: string;
    keywords?: string;
    description: string;
    staffIds: number[];
    reviewIds: number[];
    pricePositionIds: number[];
    specializationIds: number[];
    images: ISlide[];
    content?: IContentItem[];
    frequentlyAskedQuestions?: {
        title: string;
        subTitle?: string;
        text?: string;
        questions: {
            question: string;
            answer: string;
        }[];
    };
}

export class Service implements IService {
    id;
    name;
    title;
    subTitle;
    keywords;
    description;
    staffIds;
    reviewIds;
    specializationIds;
    images;
    pricePositionIds;
    content;
    frequentlyAskedQuestions;

    constructor(dto: IService) {
        this.id = dto.id;
        this.name = dto.name;
        this.subTitle = dto.subTitle;
        this.keywords = dto.keywords;
        this.title = dto.title;
        this.description = dto.description;
        this.staffIds = dto.staffIds || [];
        this.staffIds = dto.staffIds || [];
        this.reviewIds = dto.reviewIds || [];
        this.specializationIds = dto.specializationIds || [];
        this.images = dto.images || [];
        this.pricePositionIds = dto.pricePositionIds || [];
        this.content = dto.content || [];
        this.frequentlyAskedQuestions = dto.frequentlyAskedQuestions;
    }

    get titlesList() {
        return this.content.filter((el) => ["title-1", "title-2"].includes(el.type) && el.text).map((el) => el.text || "");
    }

    get reviews() {
        return reviewList.getElements().filter((el) => this.reviewIds.includes(el.id));
    }

    get pricePositions() {
        return priceList.getElements().filter((el) => this.pricePositionIds.includes(el.id));
    }

    get minPrice() {
        return Math.min(...this.pricePositions.map((el) => el.prices.regular[0] ?? 0));
    }

    get meta() {
        return {
            title: this.title,
            description: this.description,
            keywords: this.keywords
        };
    }
}

export class ServiceList {
    elements: Ref<Service[]>;

    constructor(dto: IService[]) {
        this.elements = ref(dto.map((el) => new Service(el)).sort((a, b) => a.name.localeCompare(b.name)) || []);
    }

    setElements(dto: IService[]) {
        this.elements.value = dto.map((el) => new Service(el)).sort((a, b) => a.name.localeCompare(b.name)) || [];
    }

    getElements() {
        return this.elements.value;
    }

    getNames() {
        return this.elements.value.map((el) => el.name);
    }

    getElementById(id: string | number) {
        return this.elements.value.find((el) => el.id == id);
    }
}
