import type { IReview } from "~/lib/review";

export const reviews: IReview[] = [
    {
        id: 24,
        rating: 5,
        name: "Liga",
        countryCode: "IE",
        subTitle: "Mommy Makeover",
        title: "No need to be nervous though as you really are in the best hands and will not be disappointed",
        text: "<p>Amazing service throughout. pre-surgery, surgery and post-surgery. Spotless clinic. You are picked up from the airport and taken straight to clinic, no waiting around, everything runs like clockwork so less time for nerves or anxiety to kick in.</p><p>No need to be nervous though as you really are in the best hands and will not be disappointed at all. Dr Łukasz Warchoł is an absolute gent. He really is amazing at what he does, and I would highly recommend him or any of the surgeons for that matter.</p><p>Amazing aftercare with nurse visits everyday and even back home the rep is still on hand with any questions or worries you may have. Everyone in clinic speaks very good English so no need to worry about the language barrier either. Couldn't recommend this clinic enough.</p>"
    },
    {
        id: 18,
        rating: 5,
        name: "David",
        countryCode: "US",
        subTitle: "Knee replacement",
        title: "Everyone from the coordinator to the nurse, surgeon and therapist were excellent",
        text: "We came from the US for a knee replacement. Prior to the surgery at Allmedica, I had undergone 18 orthopaedic surgeries on various joints in the US. Hands down, this was the best and most personal care I have ever received.<br><br>Everyone from the coordinator to the nurse, surgeon and therapist were excellent. For those of you worries about a language barrier, that was NOT an issue. Should I ever need another orthopaedic procedure, I will be returning to Nowy Targ to visit Allmedica."
    },
    {
        id: 12,
        rating: 5,
        name: "Janive Sanderson",
        countryCode: "GB",
        subTitle: "Gastric Balloon",
        title: "The doctors and nurses were also really brilliant very professional",
        text: "I was really nervous about going for surgery abroad but l was quickly put at ease, after landing in Poland l was greeted by Oskar who was absolutely marvelous. The doctors and nurses were also really brilliant very professional and everything in the hospital was spotless. I highly recommend ALLMEDICA, and if l needed any more surgery l wouldn't hesitate about going back.<br><br><b>Key steps before undergoing a gastric balloon procedure</b>:<br><br>Diet Preparation:<br>- Clear Liquids: Consume clear liquids such as water, broth, and clear juices.<br>- Avoid Solid Foods: Stop consuming solid foods 24 hours before the procedure.<br>- Avoid Carbonated Drinks: Avoid drinks that can cause gas and bloating, such as sodas and sparkling water.<br><br>Lifestyle Changes:<br>- Avoid Smoking and Alcohol: Stop smoking several weeks before the procedure and avoid alcohol in the days leading up to the procedure.<br>- Adjust Medications: Ask if any changes to your medications before the procedure are necessary.<br><br>Mental Preparation:<br>- Educate Yourself: Understand the procedure, potential risks, and expected outcomes.<br>- Set Realistic Expectations: Know that the gastric balloon is a tool to assist in weight loss but requires a commitment to lifestyle changes for long-term success.<br>- Plan for Support: Consider joining a support group for individuals undergoing weight loss procedures."
    },
    {
        id: 8,
        rating: 5,
        name: "Rachel B.",
        countryCode: "GB",
        subTitle: "Gallbladder Removal",
        title: "The whole experience for me has shown so much professionalism in all areas",
        text: "I had my gallbladder removed due to very painful flare ups. The Allmedica clinic is absolutely spotless and very well run. The care given to me was second to none and excellent value for money. My heartfelt thanks go to Magda, my wonderful nurse. Dariusz, my amazing surgeon. Weronika at the clinic. Oskar our star transport co-ordinator was absolutely fantastic, he went over and above and the extra mile to make everything run so smoothly, Oscar's colleagues for transportation. Having a very kind nurse come to the hotel to change dressings etc. We were picked up on arrival from Kraków airport and taken to the Ibis Styles Hotel. Transport was provided by Oskar to take us back to Kraków for our flight home.<br><br>I would like to give a big thank you to Gillian (patient liaison manager) in Manchester. The whole experience for me has shown so much professionalism in all areas. Everyone was just so kind and helpful and couldn't do more for me. I was rather sad about leaving as Nowy Targ as it's such a beautiful place.<br><br>All I can say is very well done to you all. I can't stop telling friends and family about my amazing and wonderful experience with Allmedica. VERY HIGHLY RECOMMENDED."
    },
    {
        id: 27,
        rating: 5,
        name: "Sabrina O",
        countryCode: "UK",
        subTitle: "Labiaplasty",
        title: "I would recommend this clinic to anyone wanting to go abroad for surgery",
        text: "My treatment at the allmedica was amazing! From the host to the nurses, everyone was so friendly and caring, I was looked after constantly, even the hotel staff were lovely, this was my first time having surgery and going to Poland, it was a great experience and I would definitely do it again. I went for a labiaplasty treatment and was set on this clinic due to the great communication via email, all responses were the same day and very quick. I would recommend this clinic to anyone wanting to go abroad for surgery."
    },
    {
        id: 26,
        rating: 5,
        name: "Neda Nazemi",
        countryCode: "US",
        subTitle: "Arm Liposuction",
        title: "I'm very satisfied with all their services",
        text: "Very clean clinic and nice nurses with a professional doctor. I had arms liposuction and I am very thrilled with the result. They took care of me after the operation very well as I was here alone. I'm very satisfied with all their services."
    },
    {
        id: 21,
        rating: 5,
        name: "Patient",
        countryCode: "GB",
        subTitle: "Knee Replacement",
        title: "The medical side of things was excellent.",
        text: "The medical side of things was excellent. I had complete confidence in the consultant and I was very impressed with the thorough checks that were done before the operation. From heart check, MRSA and Xrays. Gave complete peace of mind."
    },
    {
        id: 9,
        rating: 5,
        name: "Elaine",
        countryCode: "GB",
        subTitle: "Gallbladder Removal",
        title: "A very skilled and capable man who performed my surgery",
        text: "Flawless from beginning to end!<br>Initial enquiries were made to Weronika who effectively and gently guided me through the whole process she was supportive professional patient and gave me great confidence in choosing Allmedica<br><br>Weronika then introduced me to my English-speaking host the wonderful Oscar who was an absolute comfort, incredibly helpful and diligent. Oscar was superb at his job and always made you feel like you were in safe capable hands<br><br>I was then introduced to my surgeon. Who instantly made me feel I was in very safe hands. A very skilled and capable man who performed my surgery. I am forever grateful to him, I think his name was Cichostepski Dariuz ( please correct me if this is wrong)<br><br>Finally, after my operation, I was monitored all through the night by a lovely kind nurse who checked on me and was only a buzz away, she was so efficient and kind I’m sorry I did not catch your name but thank you! Xxx<br><br>I would happily recommend Allmedica 5 stars from start to finish in every way!<br><br>Many thanks, Elaine, Scotland"
    },
    {
        id: 28,
        rating: 5,
        name: "Laurence Beaini",
        countryCode: "US",
        subTitle: "Plastic Surgery",
        title: "They took care of me and gave me the best result that I want",
        text: "<p>The best clinic in the world. They took care of me and gave me the best result that I want. They have amazing specialists and 5 stars service. I appreciate what they have done for me.</p><p>I strongly recommend Allmedica.</p>"
    },
    {
        id: 13,
        rating: 5,
        name: "Simon",
        countryCode: "GB",
        subTitle: "Hip Replacement",
        title: "I am now recovering quickly and improving daily",
        text: "I was a medical tourist from the UK. Having been let down by the NHS in England, I travelled to Allmedica in Nowy Targ. At the airport I was collected by Oskar who speaks excellent English and transported me to the clinic for an X-ray and consultation. My operation went very well, the surgeon was excellent and all the staff were very friendly, professional and caring. I was given physio therapy every day for 10 days after the operation. My physio, Artur was brilliant and got me my mobility back very quickly. I am now recovering quickly and improving daily. Many thanks to Michael and all at Allmedica for giving me my life back. Highly recommended."
    },
    {
        id: 6,
        rating: 5,
        name: "Peter W.",
        countryCode: "US",
        subTitle: "Hernia Removal",
        title: "The decision was one of the best of my life",
        text: "I had been dealing with a progressively worsening inguinal hernia for around six years, always putting off getting it fixed because of the high cost involved having it done in the U.S., anywhere other than at the Hernia Center in Rockville, Maryland, 3,000 miles from where we live. Using the internet, we discovered the Allmedica option, in Poland, and decided we could roll the surgery and some priceless sightseeing all into one travel initiative.<br><br>The decision was one of the best of my life. Not only was the medical attention superlative, but the people there who took us under their wing were incredibly friendly, diligent in their duties and just plain good folks. After I had recovered sufficiently at the B&B Hotel (a great value), my wife and I went on to do the land journey of a lifetime through Slovakia, Hungary, Romania and Bulgaria. The whole month-long experience, medical services and airfare included, cost us $3,000 less than what we would have been charged just for the surgery at the hospital in our home town. What's not to love about that?!<br><br>Thank you, Weronika, for being such a stellar correspondent, and thank you, Oskar, for making sure all our various needs were met in making sure my surgery was a success."
    },
    {
        id: 1,
        rating: 5,
        name: "Carol",
        countryCode: "GB",
        subTitle: "Gastric Sleeve",
        title: "The clinics were spotless and didn’t look like normal hospitals so very calming",
        text: "”I went and had gastric sleeve surgery here and from the moment I arrived, with Oskar waiting for me at the airport, to all the tests I had done, to the actual surgery.<br>It was like I was in a 10-star hotel, anything I needed Oskar helped with,<br>The clinics were spotless and didn’t look like normal hospitals so very calming.<br>I will definitely be going back there again, very good value.”"
    },
    {
        id: 17,
        rating: 5,
        name: "Stuart",
        countryCode: "ES",
        subTitle: "Knee replacement",
        title: "Thanks to everyone involved in ending the pain I've lived with for the past 4 years",
        text: "I went to Allmedica, Nowy Targ, Poland in March 2024 for a total knee replacement. From the moment of enquiry, I was gently guided through the process by the professional team there. It was all so easy...and very affordable. It is now almost 5 weeks later and I'm already walking without aid and am far more comfortable than before.<br><br>I want to send my thanks to everyone involved in ending the pain I've lived with for the past 4 years. If I had waited for a local hospital to do the operation I would have had at least another year of pain and, if it was anything like when I had my first knee done - I would still be learning how to use crutches!<br><br>Special thanks go to Beatta for organising everything; Oskar for being the most professional, organised, friendly and efficient host; the nurses who tended to me night and day while in the hospital; the doctors and surgery teams; and to Gorsia for the daily physio regimen. I'm a new man!"
    },
    {
        id: 7,
        rating: 5,
        name: "Patient",
        countryCode: "UK",
        subTitle: "Hernia",
        title: "The service has been incredible.",
        text: "I would like to thankyou and all the people in Poland that looked after me.<br>The service has been incredible, and I'm so pleased that I got this operation done."
    },
    {
        id: 11,
        rating: 5,
        name: "Mike",
        countryCode: "IE",
        subTitle: "Gastric Balloon",
        title: "The surgery went really well",
        text: "Allmedica is a superb clinic. If you have any doubts – just don’t. I was uncertain just as you would. But it all evaporated when I got to the clinic. Medical staff is fantastic, the clinic is spotlessly clean and all the equipment looked brand new. I also took the all-inclusive package, which included a stay at Ibis Styles hotel (very comfy) including breakfasts and the help from the host (in my case it was Oscar, who is just great guy to hang around with), who collected me from the airport and took care of me during my stay. The surgery went really well. I lost ca. 20 kg and maintained it for the time being. I would like to thank prof. Major, all medical staff, Oskar and Allmedica for making this happen."
    },
    {
        id: 14,
        rating: 5,
        name: "Patient",
        countryCode: "GB",
        subTitle: "Hip Replacement",
        title: "The doctors and nurses at the clinics were first class.",
        text: "Following on from the great assistance you gave prior to our trip to Poland, we have nothing but praise for our treatment at the hospital in Poland.<br>First, Oscar was superb as our host.  He has a wonderful, warm and calm personality.  His English is perfect and he went out of his way to assist both of us.  It was a pleasure to be with him.<br>Second the doctors and nurses at the clinics were first class both medically and on a personal level.<br>Finally, the physiotherapy treatment was again first class in assisting the start of the rehabilitation process.<br>I guess the best test is whether we would go back for other treatments or recommend it to others. The answer is a resounding yes."
    },
    {
        id: 29,
        rating: 5,
        name: "Maria S.",
        countryCode: "UK",
        subTitle: "Breast Lift with Implants",
        title: "I couldn't be happier",
        text: "Couldn't say anything bad. Amazing experience and my dreams have definitely come true. Feeling much more body confident, something I have always wanted to do and never thought I would. The hotel, hospital and the nurses and the doctor were amazing too. The price for the service and treatment you get is great and I would definitely return, I had breast implants and uplift. I couldn't be happier."
    },
    {
        id: 2,
        rating: 5,
        name: "Agnieszka McGowan",
        countryCode: "IE",
        subTitle: "Gastric Sleeve",
        title: "All the doctors were very professional and answered all my questions promptly.",
        text: "I had Gastric Sleeve in Allmedica.<br><br>From the very beginning, when I was picked up from the airport by Oskar, until my departure, I had only good experiences.<br><br>Oscar drove me to the hotel and was there if I need him throughout my stay.<br><br>I had several consultations to which I was transported. All the doctors were very professional and answered all my questions promptly.<br><br>On the day of the surgery, I had my last consultation with Prof. Major who, despite his young appearance, is a very experienced doctor. He was very nice and empathetic, answered all questions.<br><br>The anesthesiologist was also very understanding and dispelled all my doubts. Going into the procedure, my stress level was lower.<br><br>After the procedure, very nice and friendly nurses did everything to make me feel as comfortable as possible.<br><br>After the surgery, when I returned to the hotel a nurse was coming to check if everything was OK.<br><br>Returning home, I knew that if I needed anything, I could always contact Mrs. Weronika, which happened several times after returning to Ireland.<br>Mrs. Weronika always responds quickly to messages and tries to help.<br><br>Overall, I highly recommend Allmedica and if I or someone in my family need another medical consultation, we will only choose this clinic.<br><br>"
    },
    {
        id: 19,
        rating: 5,
        name: "Roland",
        countryCode: "GB",
        subTitle: "Knee replacement",
        title: "The hospital and the staff from the surgeon to the medical team were brilliant",
        text: "From being picked up at airport the whole experience was so stress free. I had knee replacement surgery. The hospital and the staff from the surgeon to the medical team were brilliant. I would like to thank especially Oskar for always being there with any problems and Malgorzata (physio) for always being so friendly and supportive. I would recommend this procedure to anyone."
    },
    {
        id: 23,
        rating: 5,
        name: "Lesley B.",
        countryCode: "ES",
        subTitle: "Liposuction and Tummy Tuck",
        title: "I felt very comfortable with his expertise",
        text: `<p>I attended the Allmedica clinic in March 2024 for liposuction and a tummy tuck. I have to say the service that I received was second to none. Let me be clear, we added to our package the all-inclusive option so we were escorted through the whole process by our 'host' Oskar - from pick up at the airport, to drop off afterwards. The treatment itself was done exceedingly professionally, yet personal.</p>
                <p>The clinic in Nowy Targ (Poland) is very small and I was the only person staying there overnight - I had my own nurse on duty who monitored me throughout. There were no language problems - many could speak some English, we had the 'host' who spoke perfect English and there was always Google Translate! There were many pre-tests that I wouldn't have even thought of; blood tests were done in the comfort of the hotel room; dressing changes were also done in the hotel bedroom and Oskar also organised for the lymphatic drainage masseur to attend me at the hotel.</p>
                <p>Prior to the operation the surgeon discussed options with me and made sure I understood what was going to happen. I felt very comfortable with his expertise.</p>
                <p>I am writing this 3 weeks after the operation, and can honestly say that I am ecstatic with the results.</p>
                <p>Thank you to all involved in providing this treatment. I would (and have) recommended others to you.</p>`
    },
    {
        id: 4,
        rating: 5,
        name: "Stacy",
        countryCode: "GB",
        subTitle: "Gastric Sleeve",
        title: "The nurses and the amazing surgeon were all fantastic",
        text: "I went for a gastric sleeve in November last year. When choosing the clinic I was checking opinions on the clinic and the surgeon. To be honest I wasn’t sure if the decision I was about to make was the right one. Once I got there my worries were eased. I was picked up from the airport by Michal, who took me to the hotel and helped me on number of occasions during my stay. The nurses and the amazing surgeon were all fantastic. The clinic itself is very modern and clean. From the moment I first contacted Allmedica I was taken care of and all my questions were answered. Thank you all at Allmedica!"
    },
    {
        id: 16,
        rating: 5,
        name: "Patient",
        countryCode: "GB",
        subTitle: "Hip Replacement",
        title: "I can’t emphasise enough just what an amazing experience I had.",
        text: "I wanted to thank your organisation for my experience which far exceeded anything I could have imagined.<br>From the start of my enquiries responses to all communications were swift and comprehensive.  The itinerary was very helpful and set out what was to be expected.<br><br>I was very nervous about going to Poland for the operation - particularly with Covid concerns etc - but everything went so well - and I really think this is largely due to you Gill and the hospital team.  You went above and beyond to make sure that we were looked after and I can’t thank you enough.<br><br>I can’t emphasise enough just what an amazing experience I had - but I think you’ll begin to get the picture if I told you that when I left the hospital at the hospital my nurse hugged me; when I left the hotel the Lady from the hotel reception hugged me - and when I said goodbye to Oskar at the airport he hugged me!.There were definitely tears!<br>During the two weeks I spent in Poland I connected with some truly lovely human beings - yourself included -  who went over and above my expectations."
    },
    {
        id: 20,
        rating: 5,
        name: "Patient",
        countryCode: "GB",
        subTitle: "Breast Implants",
        title: "The team have been excellent.",
        text: "The hospital team have been excellent as previously.<br>Very attentive and great at finding the best solutions as each day progresses.<br>Hotel is a good standard, food and cleaning service is as good as before."
    },
    {
        id: 5,
        rating: 5,
        name: "Chris",
        countryCode: "PL",
        subTitle: "Gastric Sleeve",
        title: "The surgery was successful and I feel really well",
        text: "I came to Allmedica in August 2020 for gastric sleeve surgery. My coordinator helped me to feel at ease and dispelled my doubts about whether or not to go for it. Since I live just 300 km away I decided to go by car and stay at the nearby hotel. The clinic looks really fancy with really modern equipment and the staff is really nice. The surgery was successful and I feel really well. I can recommend Allmedica and prof. Major without any hesitation."
    },
    {
        id: 22,
        rating: 5,
        name: "Patient",
        countryCode: "GB",
        subTitle: "Knee Replacement",
        title: "I am now pain free!",
        text: "I am now pain free and I have a new life to look forward to."
    },
    {
        id: 10,
        rating: 5,
        name: "Andrew G.",
        countryCode: "GB",
        subTitle: "Gastric Balloon",
        title: "Highly recommended",
        text: "Fantastic care, good accommodation (even without Netflix). Great reassurance post-op. Still checking on me all the time. Highly recommended."
    },
    {
        id: 15,
        rating: 5,
        name: "Patient",
        countryCode: "GB",
        subTitle: "Hip Replacement",
        title: "It was outstanding!",
        text: "Let me say straight away that I thought it was outstanding!<br>thank you for all the assistance you gave me before I set off on my travels, not only dealing with the unexpected tablet problem at this end, but also sorting out a solution at the Polish end.<br>I was very impressed by the efficiency of your services, the quality of the care and attention I received in Poland both in the hospital and also the hotel."
    },
    {
        id: 3,
        rating: 5,
        name: "Briony Fawcett",
        countryCode: "GB",
        subTitle: "Gastric Sleeve",
        title: "The nurses were so attentive too",
        text: "Had a gastric sleeve here, everyone looked after me so well, they were very thorough. Prof major did the surgery and his consultation was great.<br>Oscar was amazing I can honestly say I wouldn't have managed without him. The nurses were so attentive too."
    }
];
